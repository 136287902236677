import { styled } from "styled-components";
import { useScreenType } from "../../Devicets";
import { handleOpenNewTab, Language } from "../../Componets/common";
import Strings from "../Playsongs/string";
import { useLanguage } from "../../Componets/Languge/LanguageContext";

const SeparatorView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.5625vw; //0.8333vw;
    border: solid 0.0521vw #222;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /*width: 76.0417vw;
    margin: 2.474vw 0 0 0.0651vw;
    border: solid 0.1302vw #222;*/
    width: 79.1667vw;
    margin-top: 2.474vw;
    border: solid 0.1302vw #222;
  }

  @media (max-width: 767px) {
    width: 92.6744vw;
    margin: 3.2558vw 0 0;
    border: solid 0.2326vw #222;
  }
  height: 0;
`;

const MainView = styled.div`
  @media (min-width: 1024px) {
    width: 100vw;
    padding: 3.125vw 7.1875vw 2.6563vw 8.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 3.125vw 0 3.125vw 3.0599vw;
  }
  @media (max-width: 767px) {
    padding: 5.5814vw 3.6047vw 5.3488vw 3.7209vw;
  }

  background-color: #fff;
`;

const InfoView = styled.div``;

const CopyrightLineView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 2.3438vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 767px) {
  }
`;
const LogoView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  @media (min-width: 1024px) {
    width: 33.5938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 41.0938vw;
  }
  @media (max-width: 767px) {
    width: 80.5128vw;
  }
`;

const OtherLogoView = styled(LogoView)`
  @media (min-width: 1024px) {
    width: 24.1016vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 27.2786vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.2558vw;
    width: 47.6744vw;
  }
`;

const OtherLogo = styled.div`
  @media (min-width: 1024px) {
    width: 4.7656vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 5.2083vw;
  }
  @media (max-width: 767px) {
    width: 9.3023vw;
  }
`;

const Other2Logo = styled.div`
  @media (min-width: 1024px) {
    width: 7vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 8.8542vw;
  }
  @media (max-width: 767px) {
    width: 15.814vw;
  }
`;
const LogoBicycleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.7292vw;
    width: 9.0078vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10.8854vw;
  }
  @media (max-width: 767px) {
    width: 21.1628vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogoLineView = styled.div`
  @media (min-width: 1024px) {
    width: 0.1094vw;
    height: 2.6016vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 0.1042vw;
    height: 3.4896vw;
  }
  @media (max-width: 767px) {
    height: 7.1279vw;
    width: 0.2vw;
  }
  background-color: #222;
`;
const LogoPlaysongsView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.849vw;
    width: 5.3438vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 7.525vw;
  }
  @media (max-width: 767px) {
    width: 12.3256vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogoHomeView = styled.div`
  @media (min-width: 1024px) {
    width: 5.3281vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.4323vw;
  }
  @media (max-width: 767px) {
    width: 11.4884vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogocharliesView = styled.div`
  @media (min-width: 1024px) {
    width: 4.9531vw;
    margin-top: 0.2083vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.794vw;
  }
  @media (max-width: 767px) {
    width: 11.1628vw;
  }

  &:hover {
    cursor: pointer;
  }
`;

const TextView = styled.div`
  line-height: 1.86;
  @media (min-width: 1024px) {
    margin-top: 1.5625vw;
    font-size: 1.0938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.0313vw; //2.6042vw;
    font-size: 1.8229vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.4vw;
    font-size: 3.7209vw;
    line-height: 1.63;
  }
  text-align: left;
  color: #222;
`;

const CopyrightTextView = styled.div`
  line-height: 1.86;
  @media (min-width: 1024px) {
    // margin-top: 1.5625vw;
    font-size: 1.25vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.8229vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.4vw;
    font-size: 3.7209vw;
    line-height: 1.63;
  }
  text-align: left;
  color: #222;
`;

const TitleTextView = styled.span`
  @media (min-width: 1024px) {
    font-size: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.0833vw;
  }
  @media (max-width: 767px) {
    font-size: 3.7209vw;
  }
  color: #222;
`;

export default function BFooter() {
  return (
    <footer>
      <DesktopFooter />
    </footer>
  );
}

function DesktopFooter() {
  const screenType = useScreenType();
  const language = useLanguage();

  return (
    <>
      <MainView>
        <InfoView>
          <LogoView>
            <LogoBicycleView
              onClick={() =>
                handleOpenNewTab("http://www.playsongs.co.kr/bicycle/")
              }
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/bicycle%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoBicycleView>
            <LogoLineView />
            <LogoPlaysongsView
              onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/ps%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoPlaysongsView>
            <LogoHomeView
              onClick={() => handleOpenNewTab("https://playsongshome.com/")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/home%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoHomeView>
            <LogocharliesView
              onClick={() =>
                handleOpenNewTab("https://www.charliesbigredhouse.com/")
              }
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/charlies%403x.png"
                alt=""
                width={"100%"}
              />
            </LogocharliesView>
          </LogoView>

          <TextView className="text_wrap">
            <TitleTextView>
              {Strings.bicycleFooterString[language.language].title}
            </TitleTextView>
            {
              Strings.bicycleFooterString[language.language].description[
                screenType
              ]
            }
          </TextView>
        </InfoView>
        <SeparatorView />
        <CopyrightLineView>
          <CopyrightTextView>
            {Strings.bicycleFooterString.copy}
          </CopyrightTextView>

          <OtherLogoView>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/ieyc-square-logo-with-text%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/mom-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/bcli%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <Other2Logo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/choice-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </Other2Logo>
          </OtherLogoView>
        </CopyrightLineView>
      </MainView>
    </>
  );
}
