import styled from "styled-components";

const CardSection = styled.div`
  display: inline-block;
  object-fit: contain;

  @media (min-width: 1024px) {
    width: 15.3125vw;
    margin-right: 0.9375vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 25.5208vw;
    margin-right: 3.8542vw;
  }
  @media (max-width: 767px) {
    width: 46.1538vw;
    margin-right: 1.5385vw;
  }
`;

const Image = styled.div`
  width: 100%;
`;
const Year = styled.div`
  width: 100%;
  line-height: 2.25;
  font-weight: bold;
  text-align: left;
  color: #000;
  @media (min-width: 1024px) {
    font-size: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
  }
  @media (max-width: 767px) {
  }
`;

const Descript = styled.div`
  width: 100%;

  line-height: 1.44;
  text-align: left;
  color: #000;

  @media (min-width: 1024px) {
    font-size: 1.25vw;
    line-height: 1.63;
    letter-spacing: -0.0625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.3438vw;
    letter-spacing: -0.1641vw;
  }
  @media (max-width: 767px) {
  }
`;

interface IHistoryCard {
  img: string;
  year: string;
  descript: string;
  selected: boolean;
  onClick: () => void;
}

export function HistoryCard({
  img,
  year,
  descript,
  onClick,
  selected,
}: IHistoryCard) {
  return (
    <CardSection onClick={() => onClick()}>
      <Image>
        <img src={img} alt={year} width="100%" />
      </Image>
      <Year>{year}</Year>
      <Descript className="text_wrap">{descript}</Descript>
    </CardSection>
  );
}

const MSection = styled(CardSection)`
  width: 46.1538vw;
  margin-right: 1.5385vw;
`;
const MYear = styled(Year)`
  font-size: 5.1282vw;
`;

const MDescript = styled(Descript)`
  font-size: 3.5897vw;
  line-height: 1.71;
  letter-spacing: -0.1795vw;
`;

export function MHistoryCard({
  img,
  year,
  descript,
  selected,
  onClick,
}: {
  img: string;
  year: string;
  descript: string;
  selected: boolean;
  onClick: Function;
}) {
  return (
    <MSection onClick={() => onClick()}>
      <Image>
        <img src={img} alt={year} width="100%" />
      </Image>
      <MYear>{year}</MYear>
      <MDescript className="text_wrap">{descript}</MDescript>
    </MSection>
  );
}
