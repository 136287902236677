import { ISidebarMenu, NavBack } from "../SidebarMenu";
import { MenuData } from "../../Json/MenuData";
import { IMenuProps } from "../Navts";
import { styled } from "styled-components";
import PSidebarSubMenu from "./PSidebarSubMenu";

export const MoveLabel = styled.div`
  width: 100%;
  height: 8.3333vw;
  padding-left: 4.8177vw;
  font-size: 2.6042vw;
  text-align: left;
  font-weight: bold;

  display: flex;
  align-items: center;
  color: #08348a;
  border-bottom: 0.5px solid #707070;

  //text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: 4.6512vw;
    height: 14.6512vw;
    padding-left: 4.8177vw;
  }
`;

const LogoView = styled.img`
  width: 13.2943vw;
  height: 6.6016vw;
  margin-left: 4.8177vw;
  margin-top: 2.5651vw;

  z-index: 11;

  @media (max-width: 767px) {
    width: 23.7442vw;
    height: 11.7907vw;

    margin-top: 5.7209vw;
    margin-left: 4.8177vw;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Section = styled.div`
  width: 48.9583vw;
  margin-top: 8.7674vw;
  @media (max-width: 767px) {
    width: 74.4186vw;
    margin-top: 8.7674vw;
  }
`;

const SidebarNav = styled.div<{ $sidebar: boolean }>`
  background: #ffffff;
  width: 48.9583vw;
  height: 100vh;
  right: ${({ $sidebar }) => ($sidebar ? "0" : "-150%")};
  display: flex;
  //justify-content: end;
  flex-direction: column;
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;

  @media (max-width: 767px) {
    width: 74.4186vw;
  }
`;

const CloseButton = styled.div`
  clear: both;
  object-fit: contain;

  width: 3.2031vw;
  height: 3.2031vw;
  margin-top: 2.5651vw;
  margin-right: 5.8073vw;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 5.7209vw;
    height: 5.7209vw;

    margin-top: 5.7209vw;
    margin-right: 59.3304vw;
  }
  z-index: 15;
`;

export default function PSidebarMenu({
  showSidebar,
  closeSidebar,
}: ISidebarMenu) {
  return (
    <NavBack $sidebar={showSidebar}>
      <CloseButton onClick={closeSidebar}>
        <img
          src="https://playsongshome.playsongs.ai/icon/button-x.png"
          alt=""
          width={"100%"}
        />
      </CloseButton>
      <SidebarNav $sidebar={showSidebar}>
        <LogoView src="https://playsongshome.playsongs.ai/logo/logo.png" />
        <Section>
          {MenuData.map((item: IMenuProps, index) => {
            return (
              <PSidebarSubMenu
                item={item}
                closeSidebar={closeSidebar}
                key={index}
              />
            );
          })}
        </Section>
      </SidebarNav>
    </NavBack>
  );
}

/*

        
       
      </SidebarNav>
*/
