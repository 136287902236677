interface IEnglishSectionStepInfo {
  title: string;
  pc: string;
  tablet: string;
  mobile: string;
}
interface StringEnglishClub {
  section2: {
    step1: IEnglishSectionStepInfo;
    step2: IEnglishSectionStepInfo;
    step3: IEnglishSectionStepInfo;
    step4: IEnglishSectionStepInfo;
  };
}

interface IBasicInfo {
  pc: string;
  tablet: string;
  mobile: string;
}

interface IFooterString {
  title: string;
  description: IBasicInfo;
  copy: string;
}

interface IBicycleFooterString {
  ENG: {
    title: string;
    description: IBasicInfo;
  };
  KOR: {
    title: string;
    description: IBasicInfo;
  };
  copy: string;
}

interface IAppDecriptionInfo {
  title: string;
  desc: string;
}
interface IAppService {
  title: string;
  homeDecription: IAppDecriptionInfo;
  partyDecription: IAppDecriptionInfo;
  download: string;
}
class Strings {
  static footerString: IFooterString = {
    title: "바이시클(주)\n",
    description: {
      pc: "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839 | 바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호 | 개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
      tablet:
        "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839\n바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호\n개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
      mobile:
        "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839\n바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호\n개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
    },
    copy: "© BICYCLE Co.,Ltd. ALL RIGHTS RESERVED.",
  };

  static bicycleFooterString: IBicycleFooterString = {
    KOR: {
      title: "바이시클(주)\n",
      description: {
        pc: "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839 | 바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호 | 개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
        tablet:
          "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839\n바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호\n개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
        mobile:
          "대표이사 : 이도희 | 사업자등록번호 : 114-86-61839\n바이시클 대표번호 1668-0822\n주소 : 서울특별시 용산구 독서당로 98 501호\n개인정보 보호 책임자 : 안영주 info@playsongs.co.kr",
      },
    },
    ENG: {
      title: "Bicycle Co., Ltd\n",
      description: {
        pc: "CEO: Lee Do-hee | Business Number: 114-86-61839 (Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy officer: Young-Ju An Info@playsongs.co.kr",
        tablet:
          "CEO: Lee Do-hee | Business Number: 114-86-61839 (Republic of Korea)\nTel. 1668-0822 | Address: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy Officer: Young-Ju An Info@playsongs.co.kr",
        mobile:
          "CEO: Lee Do-hee | Business Number: 114-86-61839\n(Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief Officer: Young-Ju An\nInfo@playsongs.co.kr",
      },
    },
    copy: "© BICYCLE Co.,Ltd. ALL RIGHTS RESERVED.",
  };

  static englishClub: StringEnglishClub = {
    section2: {
      step1: {
        title: "1. 언어발달 촉진: ",
        pc: " 이 시기의 아이들은 언어를 습득하는 데 매우 예민합니다.\n영어를 자연스럽게 노출하는 환경에서 아이들은 언어발달을 촉진할 수 있습니다.",
        tablet:
          " 이 시기의 아이들은 언어를 습득하는 데 매우 예민합니다.\n영어를 자연스럽게 노출하는 환경에서 아이들은 언어발달을 촉진할 수 있습니다.",
        mobile:
          " 이 시기의 아이들은 언어를 습득하는 데 매우 예민합니다. 영어를 자연스럽게 노출하는 환경에서 아이들은 언어발달을 촉진할 수 있습니다.",
      },
      step2: {
        title: "2. 언어 감각 형성: ",
        pc: " 놀이를 어린 시절에 접하면 다중 언어에 대한 감각을 형성할 수 있습니다.\n따라서 영어와 놀이를 접하면, 언어를 보다 자연스럽게 이해하고 습득하게 됩니다.",
        tablet:
          " 놀이를 어린 시절에 접하면 다중 언어에 대한 감각을 형성할 수 있습니다. 따라서 영어와 놀이를 접하면, 언어를 보다 자연스럽게 이해하고 습득하게 됩니다.",
        mobile:
          " 놀이를 어린 시절에 접하면 다중 언어에 대한 감각을 형성할 수 있습니다. 따라서 영어와 놀이를 접하면, 언어를 보다 자연스럽게 이해하고 습득하게 됩니다.",
      },
      step3: {
        title: "3. 문화적 이해와 개방성: ",
        pc: " 영어를 통해 다른 문화와 사고 방식을 경험하므로,\n아이들은 더 넓은 세계를 이해하고 개방적인 마음가짐을 갖게 됩니다.",
        tablet:
          " 영어를 통해 다른 문화와 사고 방식을 경험하므로, 아이들은 더 넓은 세계를 이해하고 개방적인 마음가짐을 갖게 됩니다.",
        mobile:
          " 영어를 통해 다른 문화와 사고 방식을 경험하므로, 아이들은 더 넓은 세계를 이해하고 개방적인 마음가짐을 갖게 됩니다.",
      },
      step4: {
        title: "4. 자신감 향상: ",
        pc: "어린 나이에 접하는 영어는, 아이들이 다른 언어를 습득하는 것에 대해\n자신감을 얻게 해줍니다.\n​이는 영어에 대해 긍정적인 태도를 형성하는 데 도움이 됩니다.",
        tablet:
          "어린 나이에 접하는 영어는, 아이들이 다른 언어를 습득하는 것에 대해 자신감을 얻게 해줍니다. 이는 영어에 대해 긍정적인 태도를 형성하는 데 도움이 됩니다.",
        mobile:
          "어린 나이에 접하는 영어는, 아이들이 다른 언어를 습득하는 것에 대해 자신감을 얻게 해줍니다. 이는 영어에 대해 긍정적인 태도를 형성하는 데 도움이 됩니다.",
      },
    },
  };
  static appserviceString: IAppService = {
    title: "APP SERVICE",
    download: "앱 다운받으러 가기",
    homeDecription: {
      title: "Playsongshome",
      desc: "6만 명 이상 고객이 선택한 프로그램 플레이송스를 집에서!\n음악치료사들이 기획한! 유일한 음악중심 통합발달 프로그램!\n미국 엄마들에게도 인정받은 '맘스초이스 어워드' 골드 메달!\n영상과 교구재 멀티링크로 더욱 효과적인 프로그램.\n국어로 한번 영어로 한번 자연스러운 첫 영어 만남.\n월령별 발달에 맞는 체계적인 놀이법과 기획된 음악을 통해 아이들은 \n신나게 놀면서도 밸런스 있게 발달하고 인지능력을 키웁니다.\n2007년부터 현장 경험과 데이터 기반으로 만들어진 프로그램 입니다.",
    },
    partyDecription: {
      title: "Playsong's Party",
      desc: "플레이송스 사운드북을 통해 음악으로 배우고 학습하여\n아이의 사고력과 창의성을 증진시키기 위한 놀이학습 프로그램",
    },
  };
  static getEnglishClubSection2ForDevice(
    step: number,
    deviceType: "pc" | "tablet" | "mobile"
  ): string {
    if (step === 1) return this.englishClub.section2.step1[deviceType];
    else if (step === 2) return this.englishClub.section2.step2[deviceType];
    else if (step === 3) return this.englishClub.section2.step3[deviceType];
    else return this.englishClub.section2.step4[deviceType];
  }
}
export default Strings;
