import "../../Css/nav.css";
import { useState } from "react";
import { Language, scrollTo } from "../../Componets/common";
import { Desktop, TabletMobile } from "../../Device";
import { useLanguage } from "../../Componets/Languge/LanguageContext";
import { BicycleMenuData } from "../../Json/MenuData";
import {
  TitelSectionB,
  OutlinemenuViewB,
  IMenuProps,
  MenuSectionB,
  LogoViewB,
  NavB,
  LanguageSelectView,
} from "../../Componets/Navts";
import { BMenu } from "../../Componets/PlaysongsMenu/PMenu";
import SidebarMenu from "../../Componets/SidebarMenu";

function Header() {
  const { language, setLanguage } = useLanguage();
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "ENG" ? "KOR" : "ENG"));
  };

  const [showSidebar, setShowSidebar] = useState(false);
  function closeSidebar() {
    setShowSidebar(false);
  }

  return (
    <NavB $menubaropen={false}>
      <Desktop>
        <TitelSectionB>
          <LogoViewB
            onClick={() => scrollTo("Banner")}
            src="https://playsongshome.playsongs.ai/bicycle/logo.png"
          />
        </TitelSectionB>
        <MenuSectionB>
          {BicycleMenuData.map((item: IMenuProps, index) => {
            return <BMenu item={item} key={index * 100} />;
          })}
        </MenuSectionB>
        <LanguageSelectView onClick={() => toggleLanguage()}>
          <Language select={language === "KOR"}>한국어</Language> |{" "}
          <Language select={language === "ENG"}>Eng</Language>{" "}
        </LanguageSelectView>
      </Desktop>
      <TabletMobile>
        <TitelSectionB>
          <OutlinemenuViewB
            onClick={() => setShowSidebar(true)}
            src="https://playsongshome.playsongs.ai/bicycle/2637%402x.png"
          />
          <LogoViewB
            onClick={() => scrollTo("Banner")}
            src="https://playsongshome.playsongs.ai/bicycle/logo.png"
          />
        </TitelSectionB>

        <SidebarMenu showSidebar={showSidebar} closeSidebar={closeSidebar} />
      </TabletMobile>
    </NavB>
  );
}

export default Header;
