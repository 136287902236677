import "./../Css/bicycle.css";
import Header from "../screens/Bicycle/BHeader";
import { Helmet } from "react-helmet-async";
import { LanguageProvider } from "../Componets/Languge/LanguageContext";
import BHome from "../screens/Bicycle/BHome";
import { BBody } from "../Componets/common";
import BFooter from "../screens/Bicycle/BFooter";

export default function Bicycle() {
  return (
    <LanguageProvider>
      <BicycleView />
    </LanguageProvider>
  );
}

function BicycleView() {
  return (
    <>
      <Helmet>
        <title>Bicycle</title>
        <link rel="icon" href={"favicon.ico"} />
      </Helmet>
      <BBody>
        <Header />
        <BHome />
        <BFooter />
      </BBody>
    </>
  );
}
