import { useState } from "react";
import PSidebarMenu from "../../Componets/PlaysongsMenu/PSidebarMenu";
import PMenu from "../../Componets/PlaysongsMenu/PMenu";
import { TabletMobile, Desktop } from "../../Device";
import { MenuData } from "../../Json/MenuData";
import {
  Nav,
  TitelSection,
  LogoView,
  OutlinemenuView,
  IMenuProps,
  MenuSection,
} from "../../Componets/Navts";

//
export default function PHeader() {
  const [showSidebar, setShowSidebar] = useState(false);
  function closeSidebar() {
    setShowSidebar(false);
  }

  const [showMenubar, setShowMenubar] = useState(false);
  function closeMenubar() {
    setShowMenubar(false);
  }
  function openMenubar() {
    setShowMenubar(true);
  }

  const goMain = () => window.location.replace("/");

  return (
    <Nav $menubaropen={showMenubar} onMouseLeave={closeMenubar}>
      <TabletMobile>
        <LogoView
          onClick={goMain}
          src="https://playsongshome.playsongs.ai/playsongs/logo-playsongs.png"
        />
        <OutlinemenuView
          onClick={() => setShowSidebar(true)}
          src="https://playsongshome.playsongs.ai/playsongs/outlinemenu.png"
        />
        <PSidebarMenu showSidebar={showSidebar} closeSidebar={closeSidebar} />
      </TabletMobile>
      <Desktop>
        <TitelSection>
          <LogoView
            onClick={goMain}
            src="https://playsongshome.playsongs.ai/playsongs/logo-playsongs.png"
          />
        </TitelSection>
        <MenuSection>
          {MenuData.map((item: IMenuProps, index) => {
            return (
              <PMenu
                item={item}
                key={index * 10}
                showMenubar={showMenubar}
                openMenubar={openMenubar}
                closeMenubar={closeMenubar}
              />
            );
          })}
        </MenuSection>
      </Desktop>
    </Nav>
  );
}
