/*import React from "react";
import Header from "./Componets/Header";
import Footer from "./Componets/Footer";
import Home from "./screens/Home";
import { ScrollSnapWrap, SnapFooter } from "./screens/common";
import { Default, Mobile } from "./Device";



function Root() {
    return (
        <>
            <Default>
                <ScrollSnapWrap >
                    <Header />
                    <Home />
                    <Footer />
                </ScrollSnapWrap>

            </Default>
            <Mobile>
                <Header />
                <Home />
                <Footer />
            </Mobile>
        </>
    );
}
*/

import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { ModalProvider } from "./Componets/ModalContext";
import ApplyForm from "./screens/Playsongs/ApplyForm";
import { ScrollToTop } from "./screens/Playsongs/playsongsts";

function Root() {
  return (
    <HelmetProvider>
      <ModalProvider>
        <ScrollToTop />
        <Outlet />
        <ApplyForm />
      </ModalProvider>
    </HelmetProvider>
  );
}
export default Root;
