import React, { createContext, useState, useContext, FC } from "react";

// 언어 타입
type Language = "KOR" | "ENG";

// Context 생성
interface LanguageContextProps {
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
}

const LanguageContext = createContext<LanguageContextProps>({
  language: "KOR", // 기본값 설정
  setLanguage: () => {},
});

// Context Provider 컴포넌트
export function LanguageProvider({ children }: any) {
  const [language, setLanguage] = useState<Language>("KOR");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

// 커스텀 훅 생성
export const useLanguage = () => useContext(LanguageContext);
