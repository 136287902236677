import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";

// common
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

export const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background-size: cover;
  overflow-x: hidden;
`;

export const Body = styled.div`
  margin-top: 5.2083vw;
  @media (max-width: 767px) {
    margin-top: 18.6047vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 13.0208vw;
  }
`;

export const PBody = styled.div`
  margin-left: 16.6667vw;
  margin-right: 16.6667vw;
  @media (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 10.4167vw;
    margin-right: 10.4167vw;
  }

  @media (max-width: 767px) {
    margin-left: 6.9767vw;
    margin-right: 6.9767vw;
  }
`;

// about
export const CharView = styled.img`
  position: absolute;
`;

//

export const TitelView = styled.div`
  margin-top: 3.125vw;
  font-size: 2.6042vw;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  color: #08348a;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 6.9767vw;
    line-height: 1.5;
    white-space: pre-wrap;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 5.2083vw;
    line-height: 1.38;
    white-space: pre-wrap;
  }
`;
export const SubZone = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 66.6667vw;
`;

export const SubTitle = styled.div`
  display: flex;
  width: 23.9583vw;

  img {
    width: 2.6615vw;
    height: 3.0417vw;

    @media (max-width: 767px) {
      width: 4.6047vw;
      height: 5.2558vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 3.6979vw;
      height: 4.2318vw;
    }
  }

  span {
    margin-left: 0.9844vw;
    font-size: 2.0833vw;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: #000;

    @media (max-width: 767px) {
      font-size: 4.6512vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    margin-top: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    justify-content: center;
    margin-top: 5.2083vw;
  }
`;

export const SubDescription = styled.div`
  span {
    font-size: 1.5625vw;
    line-height: 1.67;
    letter-spacing: -0.0469vw;
    text-align: left;
    color: #000;

    @media (max-width: 767px) {
      font-size: 4.186vw;
      line-height: 1.44;
      letter-spacing: -0.0837vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      line-height: 1.75;
      letter-spacing: -0.0781vw;
    }
  }

  @media (max-width: 767px) {
    width: 85.5814vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    display: flex;
    justify-content: center;
    margin-top: 2.6042vw;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    width: 39.0625vw;
    margin-left: 3.8542vw;
  }
`;
// schedule
export const SelectSectionView = styled.div`
  clear: both;

  width: 47.0833vw;
  margin-top: 5.2083vw;
  column-gap: 1.0417vw;

  @media (max-width: 767px) {
    width: 85.5814vw;
    margin-top: 7.4419vw;
    column-gap: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 58.8542vw;
    margin-top: 4.1667vw;
    column-gap: 2.0833vw;
  }

  display: flex;
  justify-content: space-around;

  margin-left: auto;
  margin-right: auto;
`;
export const ButtonView = styled.div<{ $select: boolean }>`
  width: 22.9167vw;
  height: 4.6875vw;

  @media (max-width: 767px) {
    width: 40.4651vw;
    height: 15.5814vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 28.3854vw;
    height: 7.8125vw;
  }

  background-color: ${(props) => (props.$select ? "#eff4ff" : "#fff")};
  border: solid ${(props) => (props.$select ? "0px" : "1px")} #707070;

  span {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    color: #222;
    font-size: 1.5625vw;
    line-height: 2.17;
    letter-spacing: normal;

    @media (max-width: 767px) {
      font-size: 5.581vw;
      line-height: 2.71;
      letter-spacing: -0.1116vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 3.125vw;
      line-height: 2.71;
      letter-spacing: normal;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
export const ActionButton = styled(ButtonView)`
  @media (max-width: 767px) {
    width: 65.1163vw;
    height: 13.9535vw;
  }

  background-color: #08348a;
  span {
    @media (max-width: 767px) {
      font-size: 5.581vw;
      line-height: 2.71;
      letter-spacing: normal;
    }
    color: #fff;
  }
`;

export const ScheduleInfoView = styled.div`
  font-size: 1.875vw;
  color: #222;
  margin-top: 3.3333vw;
  text-align: center;
  line-height: 1.39;
  letter-spacing: -0.0375vw;

  @media (max-width: 767px) {
    margin-top: 7.4419vw;
    font-size: 4.6512vw;
    letter-spacing: -0.093vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 4.4271vw;
    font-size: 2.6042vw;
    letter-spacing: -0.0521vw;
  }
`;

// curriculum

export const StepTitleSection = styled.div`
  width: 66.6667vw;
  height: 5.4688vw;
  border-radius: 0.2083vw;
  margin: 2.0833vw auto 0;
  background-color: #81d46b;

  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 85.5814vw;
    height: 24.4186vw;
    margin: 9.3023vw auto 0;
    border-radius: 0.9302vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    height: 13.6719vw;
    margin: 5.2083vw auto 0;
    border-radius: 0.5208vw;
  }
`;

export const StepTitleView = styled.div`
  margin-left: 4.6875vw;
  width: 5.7292vw;
  height: 3.75vw;

  @media (max-width: 767px) {
    width: 25.5814vw;
    height: 12.093vw;
    margin-left: 6.9767vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.3229vw;
    height: 9.375vw;
    margin-left: 9.1146vw;
  }
`;

export const StepDescriptView = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5.7292vw;

  width: 50.5208vw;

  @media (max-width: 767px) {
    margin-left: 9.7674vw;
    width: 52.3256vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 5.9896vw;
    width: 51.8229vw;
  }
`;

export const SetDescriptSetion = styled.div`
  width: 66.6667vw;
  height: 7.2917vw;
  padding: 1.8229vw 1.5104vw 1.8229vw 2.2917vw;
  border-radius: 0.2083vw;
  background-color: #fafafa;
  margin: auto;

  display: flex;
  align-items: center;
  span {
    font-size: 1.0417vw;
    line-height: 1.75;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;

    @media (max-width: 767px) {
      font-size: 4.6512vw;
      line-height: 1.75;
      letter-spacing: -0.1395vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      letter-spacing: -0.0781vw;
    }
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
    height: 64.8837vw;
    padding: 4.6512vw;
    border-radius: 0.9302vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    height: 27.3438vw;
    padding: 4.9479vw 4.8177vw 4.9479vw 4.9479vw;
    border-radius: 0.5208vw;
  }
`;

export const CurriculumInfoZone = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media (min-width: 1024px) {
    width: 66.6667vw;
    margin-top: 5.2083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    width: 79.1667vw;
    margin-top: 5.2083vw;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 9.3023vw;
    width: 85.5814vw;
  }
`;

export const CurriculumInfoSection = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    flex-direction: column;
    width: 33.2813vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    width: 100%;
  }
`;

export const CurriculumInfoTitle = styled(SubTitle)`
  display: flex;
  width: 100%;

  img {
    width: 2.6615vw;
    height: 3.0417vw;

    @media (max-width: 767px) {
      width: 4.6047vw;
      height: 5.2558vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 3.6979vw;
      height: 4.2318vw;
    }
  }

  span {
    letter-spacing: -0.1042vw;

    @media (max-width: 767px) {
      font-size: 4.6512vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 3.9063vw;
    }
  }
  @media (max-width: 767px) {
    justify-content: center;
    margin-top: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    justify-content: center;
    margin-top: 5.2083vw;
  }
`;

export const CurriculumInfoDescription = styled(SubDescription)`
  span {
    @media (min-width: 768px) and (max-width: 1023px) {
      text-align: left;
    }
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    // width: 33.2813vw;
    width: 100%;
    margin-top: 0.5vw;
    margin-left: 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 38.5417;
    margin-top: 0;
    justify-content: left;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 2.3256vw;
  }
`;

export const CurriculumInfoImg = styled.div`
  margin-left: 4.6875vw;
  @media (max-width: 767px) {
    margin-top: 2.3256vw;
    margin-left: 0;
  }
  img {
    object-fit: contain;
    @media (min-width: 1024px) {
      width: 28.6458vw;
      height: 15.9896vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 38.5417vw;
      height: 26.0417vw;
    }
    @media (max-width: 767px) {
      width: 85.814vw;
      //height: 46.5116vw;
    }
  }
`;

// contactus

export const ContactUsTitelView = styled(TitelView)`
  margin-top: 5.2083vw;
  margin-left: 20.3125vw;
  text-align: left;
  color: #222;
`;

export const ContactUsSlider = styled.div`
  width: 58.3333vw;
  height: 34.5313vw;
  border-radius: 0.2083vw;
  background-color: #fff;

  img {
    width: 100%;
  }
`;

export const ContactUsInfoZone = styled.div`
  display: flex;
  flex-direction: column;
  width: 60.9375vw;
  margin: 2.0833vw auto;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 5.2083vw auto;
    width: 58.3334;
  }
  @media (max-width: 767px) {
    margin: 4.6512vw auto;
    width: 82.7907vw;
  }
`;
export const ContactUsInfoSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.625vw;
  width: 100%;
`;

export const ContactUsInfoTitleIcon = styled.div`
  @media (min-width: 1024px) {
    width: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2.6042vw;
  }
  @media (max-width: 767px) {
    width: 4.6512vw;
  }
`;
export const ContactUsInfoTitle = styled.div`
  margin-left: 0.4688vw;
  width: 8.75vw;
  span {
    font-size: 1.875vw;
    font-weight: bold;
    line-height: 1.81;
    text-align: left;
    letter-spacing: -0.0375vw;
    color: #222;

    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      letter-spacing: -0.0521vw;
    }
    @media (max-width: 767px) {
      font-size: 4.186vw;
      letter-spacing: -0.2093vw;
    }
  }
  @media (max-width: 767px) {
    width: 15.1163vw;
    margin-left: 1.3953vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10.4167vw;
    margin-left: 0.9115vw;
  }
`;

export const ContactUsInfoSubScript = styled.div`
  margin-left: 2.7604vw;
  width: 53.1875vw;
  span {
    font-size: 1.875vw;
    line-height: 1.81;
    letter-spacing: -0.0375vw;
    text-align: left;
    color: #222;
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      letter-spacing: -0.0521vw;
    }
    @media (max-width: 767px) {
      font-size: 4.186vw;
      letter-spacing: -0.1256vw;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 42.7083vw;
    margin-left: 1.6927vw;
  }
  @media (max-width: 767px) {
    width: 60.4651vw;
    margin-left: 2.7907vw;
  }
`;

// family site
export const FamilySiteZone = styled.div`
  margin: 0 auto;
  @media (min-width: 1024px) {
    width: 66.6667vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
  }
`;

export const FamilySitesection = styled.div`
  clear: both;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    margin-top: 3.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
`;

export const FamilySiteInstaLine = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: end;
    margin-top: 3.7209vw;

    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
export const FamilySiteLogoLineP = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 4.1667vw; //2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;

export const FamilySiteLogoLinePImg = styled.img`
  @media (min-width: 1024px) {
    width: 8.5938vw;
    height: 4.2188vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 18.8802vw;
    height: 9.2448vw;
  }

  @media (max-width: 767px) {
    width: 26.7442vw;
    height: 13.2558vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteLogoLineBycycle = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 4.9479vw; //2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;

export const FamilySiteLogoLineBImg = styled.img`
  @media (min-width: 1024px) {
    width: 16.1458vw;
    height: 3.0208vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 32.0313vw;
    height: 5.9896vw;
  }

  @media (max-width: 767px) {
    width: 40.4651vw;
    height: 7.4419vw;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const FamilySiteInstaImg = styled.img`
  @media (min-width: 1024px) {
    margin-left: 0.625vw;
    width: 2.0833vw;
    height: 2.0833vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 1.5625vw;
    width: 5.2083vw;
    height: 5.2083vw;
  }

  @media (max-width: 767px) {
    margin-left: 1.8605vw;
    width: 9.3023vw;
    height: 9.3023vw;
  }
`;

export const FamilySiteLogoLineSection = styled.div`
  display: flex;
  align-items: end;
`;
export const FamilySiteLogoLine = styled.div`
  position: relative;
`;
export const FamilySiteLogoLineArrow = styled.div`
  position: absolute;

  @media (min-width: 1024px) {
    right: -1.0416vw;
    top: -0.8333vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    right: -3.3854vw;
    //top: -2.0833vw;
    top: 0vw;
  }
  @media (max-width: 767px) {
    top: 0;
    right: -5.5814vw;
  }

  img {
    width: 3.7209vw; /* 이미지를 컨테이너에 맞게 확장합니다. */
    height: 3.7209vw;

    @media (min-width: 1024px) {
      width: 0.8333vw;
      height: 0.8333vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 2.0833vw;
      height: 2.0833vw;
    }
  }
  // background-color: red;
`;
export const FamilySiteLogoLinePH = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 4.1667vw; //2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;
export const FamilySiteLogoLinePHImg = styled.img`
  @media (min-width: 1024px) {
    width: 5.7813vw;
    height: 5.5729vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.4531vw;
    height: 13.9323vw;
  }

  @media (max-width: 767px) {
    width: 19.3023vw;
    height: 18.8372vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteLogoLineC = styled.div`
  display: flex;
  align-items: end;

  span {
    margin-left: 1.0417vw;
    font-size: 1.5625vw;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.0313vw;
    text-align: left;
    color: #000;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 2.6042vw;
      font-size: 3.9063vw;
      letter-spacing: -0.0781vw;
    }
    @media (max-width: 767px) {
      margin-left: 7.907vw; //3.7209vw;
      font-size: 5.5814vw;
      line-height: 1.25;
      letter-spacing: -0.1116vw;
    }
  }
`;

export const FamilySiteLogoLineCImg = styled.img`
  @media (min-width: 1024px) {
    width: 5.625vw;
    height: 5.2604vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.0625vw;
    height: 13.151vw;
  }

  @media (max-width: 767px) {
    width: 20vw;
    height: 19.3023vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FamilySiteInsta = styled.div`
  span {
    margin-left: 0.2083vw;
    font-size: 0.9375vw;
    line-height: 1.94;
    letter-spacing: -0.0469vw;
    text-align: left;
    font-weight: normal;

    color: #000;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 0.5208vw;
      font-size: 2.3438vw;
      letter-spacing: -0.1172vw;
    }
    @media (max-width: 767px) {
      font-size: 4.186vw;
      letter-spacing: -0.2093vw;
    }
  }
  img {
    @media (min-width: 1024px) {
      margin-left: 0.625vw;
      width: 2.0833vw;
      height: 2.0833vw;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 1.5625vw;
      width: 5.2083vw;
      height: 5.2083vw;
    }

    @media (max-width: 767px) {
      margin-left: 1.8605vw;
      width: 9.3023vw;
      height: 9.3023vw;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: default;
  }
`;
export const FamilySiteDescription = styled.span`
  margin-top: 1.0417vw;
  font-size: 1.0417vw;
  line-height: 1.75;
  letter-spacing: -0.0521vw;
  text-align: left;
  color: #000;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.3854vw;
    font-size: 2.6042vw;
    letter-spacing: -0.1302vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.7209vw;
    font-size: 4.6512vw;
    letter-spacing: -0.2326vw;
  }
`;

// home
export const HomeZone = styled.div<{ $bgColor: string }>`
  margin-top: 0;
  width: 100vw;
  background-color: ${(props) => props.$bgColor};

  @media (min-width: 1024px) {
    height: 42.7083vw;
  }
`;

export const HomeZoneBanner = styled(HomeZone)`
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 130.2326vw;
  @media (min-width: 1024px) {
    height: 42.7083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 93.75vw;
  }
  @media (max-width: 767px) {
    height: 130.2326vw;
  }
`;

export const HomeZoneContactus = styled(HomeZone)`
  @media (min-width: 1024px) {
    height: 42.7083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 118.2292vw;
  }
  @media (max-width: 767px) {
    height: 224.4186vw;
  }
`;

export const HomeBannerView = styled.div`
  margin: auto;
  background-color: #007ac3;

  @media (min-width: 1024px) {
    width: 66.6667vw;
    // height: 37.5vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    //height: 93.7500vw;
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
    height: 48.1395vw;
  }
`;
export const HomeSection = styled.div`
  margin: auto;
  display: flex;
  height: 100%;

  @media (min-width: 1024px) {
    width: 66.6667vw;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
    flex-direction: column;
    justify-content: center;
  }
`;
export const HomeAboutusTextSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    width: 16.1458vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const HomeAboutusTitleTextView = styled.span`
  font-weight: bold;
  color: #000;

  @media (min-width: 1024px) {
    font-size: 2.6042vw;
    line-height: 1.4;
    text-align: left;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.3255vw;
    font-size: 3.6458vw;
    line-height: 1.43;
    text-align: center;
  }
  @media (max-width: 767px) {
    margin-top: 10vw;
    font-size: 6.5116vw;
    //line-height: 1.43;
    text-align: center;
  }
`;

export const HomeAboutusSubTextView = styled.span`
  color: #000;

  @media (min-width: 1024px) {
    margin-top: 1.0417vw;
    font-size: 1.25vw;
    line-height: 1.5;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.3021vw;
    font-size: 2.6042vw;
    line-height: 1.4;
    text-align: center;
  }
  @media (max-width: 767px) {
    margin-top: 2.3256vw;
    font-size: 4.6512vw;
    //line-height: 1.4;
    text-align: center;
  }
`;

export const HomeAboutusBtnView = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin-top: 2.0313vw;
    width: 16.1458vw;
    height: 4.5833vw;
    border-radius: 2.2917vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.125vw;
    width: 18.2292vw;
    height: 7.2917vw;
    border-radius: 3.6458vw;
  }
  @media (max-width: 767px) {
    margin-top: 5.5814vw;
    width: 32.5581vw;
    height: 13.0233vw;
    border-radius: 5.814vw;
  }

  span {
    font-weight: bold;
    text-align: center;
    color: #000;

    @media (min-width: 1024px) {
      font-size: 1.25vw;
      line-height: 1.67;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      line-height: 2;
    }
    @media (max-width: 767px) {
      font-size: 4.6512vw;
      line-height: 2;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const HomeAboutusCharView = styled.div`
  @media (min-width: 1024px) {
    width: 26.599vw;
    height: 26.7708vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 5.3255vw auto;

    width: 38.5807vw;
    height: 38.8411vw;
  }
  @media (max-width: 767px) {
    margin: 9.3023vw auto;

    width: 73.0465vw;
    height: 73.5116vw;
  }
`;

export const HomeContactusSection = styled.div`
  margin: auto;

  @media (min-width: 1024px) {
    width: 48.75vw;
    //width: 66.6667vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
  }
  @media (max-width: 767px) {
    width: 85.5814vw;
  }
`;
export const HomeContactusTitleView = styled.div`
  display: flex;
  justify-content: center;

  span {
    @media (min-width: 1024px) {
      margin-top: 2.8125vw;
      font-size: 2.6042vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 4.9479vw;
      font-size: 3.6458vw;
    }
    @media (max-width: 767px) {
      margin-top: 10vw;
      font-size: 6.5116vw;
    }
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    color: #000;
  }
`;

export const HomeCenterZone = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    margin-top: 4.9479vw;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 10.9302vw;
  }
`;

export const HomeContactusAp = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 23.3333vw;
    height: 23.3333vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 35.026vw;
    height: 35.026vw;
  }
  @media (max-width: 767px) {
    width: 62.5581vw;
    height: 62.5581vw;
  }
  span {
    @media (min-width: 1024px) {
      margin-top: 1.5625vw;
      font-size: 1.25vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 2.0833vw;
      font-size: 2.8646vw;
    }
    @media (max-width: 767px) {
      margin-top: 3.7209vw;
      font-size: 5.1163vw;
    }
    font-weight: bold;
    line-height: 1.67;
    text-align: center;
    color: #000;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const HomeCenterSection = styled.div`
  position: relative;
`;

export const TabletHomeCenterSectionLeft = styled(HomeCenterSection)`
  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`;
export const TabletHomeCenterSectionRight = styled(HomeCenterSection)`
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 22.526vw auto;
  }

  @media (max-width: 767px) {
    margin: 40.2326vw auto;
  }
`;

export const HomeScheduleZone = styled(HomeContactusSection)`
  @media (min-width: 1024px) {
    width: 66.6667vw;
  }
`;

export const HomeScheduleTitleView = styled.div`
  display: flex;
  justify-content: center;

  span {
    @media (min-width: 1024px) {
      margin-top: 1.875vw;
      font-size: 1.7708vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 8.2031vw;
      font-size: 2.6042vw;
    }
    @media (max-width: 767px) {
      margin-top: 18.1395vw;
      font-size: 5.1163vw;
    }

    text-align: center;
    color: #222;
  }
`;
export const HomeScheduleTitle2View = styled(HomeScheduleTitleView)`
  span {
    @media (min-width: 1024px) {
      margin-top: 0.5208vw;
      font-size: 2.6042vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 1.3021vw;
      font-size: 3.6458vw;
    }
    @media (max-width: 767px) {
      margin-top: 2.3256vw;
      font-size: 5.1163vw;
    }
    font-weight: bold;
    line-height: 1.4;
  }
`;

export const HomeSchedule = styled.div`
  display: flex;
  justify-content: center;

  img {
    @media (min-width: 1024px) {
      width: 49.2813vw;
      height: 29.1771vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 8.138vw;
      width: 77.1875vw;
      height: 45.7031vw;
    }
    @media (max-width: 767px) {
      margin-top: 10.2326vw;
      width: 85.5814vw;
      //height: 84.6977vw;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const HomeMallZone = styled(HomeScheduleZone)``;

export const HomeMallTitleView = styled(HomeScheduleTitleView)`
  display: flex;
  justify-content: center;

  span {
    @media (min-width: 1024px) {
      margin-top: 3.0729vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 14.0625vw;
    }
    @media (max-width: 767px) {
      margin-top: 25.1163vw;
    }
    color: #fff;
  }
`;

export const HomeMallTitle2View = styled(HomeScheduleTitle2View)`
  span {
    color: #fff;
  }
`;

export const HomeMall = styled.div`
  display: flex;
  justify-content: center;

  img {
    @media (min-width: 1024px) {
      margin-top: 2.0833vw;
      width: 32.1667vw;
      height: 23.0833vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 5.1432vw;
      width: 49.9609vw;
      height: 35.8594vw;
    }
    @media (max-width: 767px) {
      margin-top: 9.2791vw;
      width: 81.1628vw;
      height: 58.2558vw;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

/// wwu
export const WWUSection = styled.div`
  @media (min-width: 1024px) {
    margin-left: 16.6667vw;
    margin-right: 15.625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 10.6771vw;
    margin-right: 10.4167vw;
  }
  @media (max-width: 767px) {
    margin-left: 7.2093vw;
    margin-right: 7.2093vw;
    margin-top: 4.6512vw;
  }
`;

export const WWUTitleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 5.2083vw;
    font-size: 2.6042vw;
    line-height: 1.3;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 3.9063vw;
    line-height: 2.17;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    font-size: 5.5814vw;
    line-height: 2.71;
    letter-spacing: -0.1116vw;
  }

  font-weight: bold;

  color: #222;
`;

export const WWUDescription = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    font-size: 1.875vw;
    line-height: 1.56;
    letter-spacing: -0.0375vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    font-size: 2.6042vw;
    line-height: 1.5;
    letter-spacing: -0.0521vw;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    font-size: 4.186vw;
    line-height: 1.56;
    letter-spacing: -0.0837vw;
  }
  color: #222;
`;

export const WWUContactSection = styled.div`
  display: flex;
  justify-content: space-around;

  width: 100%;

  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 4.6512vw;
  }
`;

export const WWUContactTextSection = styled.div`
  span {
    @media (min-width: 1024px) {
      font-size: 1.875vw;
      line-height: 1.56;
      letter-spacing: -0.0375vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.6042vw;
      line-height: 3.25;
      letter-spacing: -0.0521vw;
    }
    @media (max-width: 767px) {
      font-size: 5.5814vw;

      line-height: 2.71;
      letter-spacing: -0.1116vw;
    }
    font-weight: bold;
    color: #222;
  }
`;
export const WWUContactTextInfo = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.625vw;
    font-size: 1.875vw;
    line-height: 1.56;
    letter-spacing: -0.0375vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
    font-size: 2.6042vw;
    line-height: 1.5;
  }
  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    font-size: 4.186vw;
    line-height: 1.56;
    letter-spacing: -0.0837vw;
  }

  color: #222;
`;

export const WWUContactTextInfo1 = styled(WWUContactTextInfo)`
  margin-top: 0;
  color: #007bc7;
`;

export const WWUContactEmailTitle = styled.div`
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
    font-size: 1.875vw;
    line-height: 1.81;
    letter-spacing: -0.0375vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    font-size: 2.6042vw;
    line-height: 3.25;
    letter-spacing: -0.0521vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    font-size: 5.5814vw;
    line-height: 2.71;
    letter-spacing: -0.1116vw;
  }
  font-weight: bold;
  color: #222;
`;

export const WWUContactEmail = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.4167vw;
    font-size: 1.4583vw; //1.875vw;
    line-height: 1.19;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    line-height: 1.2;
  }
  @media (max-width: 767px) {
    margin-top: 0.9302vw;
    font-size: 4.6512vw;
    line-height: 1.2;
  }
  color: #000;
`;

export const WWUImage = styled.div`
  @media (min-width: 1024px) {
    margin-left: 7.5vw;
    width: 29.6875vw;
    height: 20.2083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 8.3333vw;
    width: 28.3854vw;
    height: 19.1406vw;
  }
  @media (min-width: 767px) {
    width: 85.5814vw;
    height: 47.2093vw;
  }
  img {
    width: 100%;
  }
`;

/// English Club

export const ECImage = styled.div`
  @media (max-width: 767px) {
    margin: 9.3023vw auto 9.3023vw auto;
    width: 80.4651vw;
    height: 23.4884vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 2.6042vw auto 5.2083vw auto;
    width: 79.2969vw;
    height: 23.4375vw;
  }
  @media (min-width: 1024px) {
    margin: 2.0833vw auto 2.0833vw;
    //background-color: red;
    width: 57.2917vw;
    height: 16.875vw;
  }
`;

export const ECSubTitle = styled(SubTitle)`
  width: 100%;
  @media (max-width: 767px) {
    font-size: 4.6512vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    span {
      font-size: 3.9063vw;
    }
  }
  @media (min-width: 1024px) {
    margin-top: 2.0833vw;
  }
`;

export const ECSubDescription = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 4.6977vw;
    span {
      font-size: 4.186vw;
      line-height: 1.44;
      letter-spacing: -0.0837vw;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;

    span {
      font-size: 2.6042vw;
      line-height: 1.75;
      letter-spacing: -0.0781vw;
    }
  }
  @media (min-width: 1024px) {
    margin-top: 0.8333vw;
    margin-left: 0;
    span {
      font-size: 1.5625vw;
      line-height: 1.5;
      letter-spacing: -0.0469vw;
    }
  }
`;

export const ECSection2SubDescription = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    margin-top: 0.8333vw;
    padding: 2.0833vw 6.0417vw;
    border-radius: 0.2083vw;
    span {
      font-size: 1.5625vw;
      line-height: 1.5;
      letter-spacing: -0.0469vw;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.2552vw;
    padding: 3.6458vw 3.5156vw 3.6458vw 3.6458vw;
    border-radius: 0.5208vw;
    span {
      font-size: 2.6042vw;
      line-height: 1.75;
      letter-spacing: -0.1042vw;
    }
  }
  @media (max-width: 767px) {
    padding: 4.6512vw 3.7209vw;
    border-radius: 0.9302vw;
    span {
      font-size: 4.186vw;
      line-height: 1.44;
      letter-spacing: -0.0837vw;
    }
  }

  background-color: #f6f6f6;
`;

export const ECSection3 = styled.div`
  width: 100%;
  margin-top: 2.1354vw;
  display: flex;
  justify-content: space-between;
`;

export const ECSection3Description = styled.div`
  width: 49.9219%;

  @media (min-width: 1024px) {
    span {
      font-size: 1.5625vw;
      line-height: 1.5;
      letter-spacing: -0.0469vw;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 48.6842%;
    span {
      font-size: 2.6042vw;
      line-height: 1.75;
      letter-spacing: -0.0781vw;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 4.6512vw;
    span {
      font-size: 4.186vw;
      line-height: 1.44;
      letter-spacing: -0.1256vw;
    }
  }
`;
export const ECSection3Image = styled.div`
  width: 42.9688%;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 48.6842%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 9.3023vw;
  }
`;

export const ECTitleView = styled.div`
  font-weight: bold;
  text-align: center;
  color: #08348a;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 6.2791vw;
    line-height: 1.67;
    letter-spacing: -0.1884vw;
    white-space: pre-wrap;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 5.2083vw;
    line-height: 1.38;
    white-space: pre-wrap;
    letter-spacing: -0.1042vw;
    margin-top: 8.5938vw;
  }

  @media (min-width: 1024px) {
    margin-top: 3.125vw;
    font-size: 2.6042vw;
    line-height: 1.3;
  }
`;

export const ECTitle2View = styled(ECTitleView)`
  @media (max-width: 767px) {
    margin-top: 9.3023vw;

    font-size: 5.1163vw;
    line-height: 2.05;
    letter-spacing: -0.1535vw;
  }
`;
// App Service
export const ASTitle = styled.div`
  margin-top: 3.125vw;
  font-family: Pretendard;
  font-size: 1.5625vw;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    font-size: 3.9063vw;
  }
  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    font-size: 5.5814vw;
  }
`;

export const ASSection = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    margin-top: 3.125vw;
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 5.2083vw;
    align-items: end;
  }
  @media (max-width: 767px) {
    margin-top: 10.9302vw;
    align-items: end;
  }
`;
export const ASSection2 = styled(ASSection)`
  margin-top: 5.8854vw;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
`;
export const ASSectionTextZone = styled.div``;
export const ASSectionTitle = styled.div`
  font-family: Pretendard;
  font-size: 1.5625vw;
  font-weight: bold;
  line-height: 1.07;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.9063vw;
    margin-left: 4.1667vw;
  }
  @media (max-width: 767px) {
    font-size: 6.9767vw;
    margin-left: 7.4419vw;
  }
`;
export const ASSectionDescription = styled.div`
  margin-top: 1.0417vw;
  font-family: Pretendard;
  font-size: 1.0417vw;

  line-height: 1.6;
  color: #222222;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    letter-spacing: -0.0781vw;
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
    font-size: 4.6512vw;
    letter-spacing: -0.1395vw;
  }
`;
export const ASSectionImage = styled.img`
  width: 11.5625vw;
  object-fit: contain;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 13.0208vw;
  }
  @media (max-width: 767px) {
    width: 23.2558vw;
  }
`;
export const ASSectionDownLoadZone = styled.div`
  margin-top: 1.0417vw;
  display: flex;
  align-items: center;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.6512vw;
  }
`;
export const ASSectionDownLoadTitle = styled.div`
  font-family: Pretendard;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #222222;
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
  }
  @media (max-width: 767px) {
    font-size: 5.5814vw;
  }
`;
export const ASSectionDownLoadArrowImage = styled.img`
  width: 2.6042vw;
  height: 0.8333vw;
  margin-left: 0.8073vw;
  object-fit: contain;
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.5104vw;
    height: 2.0833vw;
    margin-left: 2.0833vw;
  }
  @media (max-width: 767px) {
    width: 11.6279vw;
    height: 3.7209vw;
    margin-left: 3.7209vw;
  }
`;

export const ASSectionDownLoadIconImage = styled.img`
  margin-left: 1.0677vw;
  height: 1.7708vw;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 4.4271vw;
    margin-left: 2.6042vw;
  }
  @media (max-width: 767px) {
    margin-left: 4.6512vw;
    height: 7.907vw;
  }
`;
export const ASSectionDownLoadIconImage2 = styled(ASSectionDownLoadIconImage)`
  margin-left: 0.8333vw;
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 2.0833vw;
  }
  @media (max-width: 767px) {
    margin-left: 3.7209vw;
  }
`;
