import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { styled } from "styled-components";

const VideoBox = styled.div`
  width: 100%;
`;

interface IBannerView {
  setShow: (visible: boolean) => void;
  url: string;
}

export function Banner({ setShow, url }: IBannerView) {
  const [muted, setMuted] = useState(true);

  const [ref, inView] = useInView({
    threshold: 1,
    onChange: (inView) => {
      setShow(!inView);
    },
  });

  useEffect(() => {
    var video = document.querySelector("video");
    if (video) video.muted = muted;
  }, [muted]);

  return (
    <VideoBox ref={ref}>
      <video
        src={url}
        width="100%"
        height="100%"
        object-fit="cover"
        muted
        playsInline
        autoPlay
        loop
        onClick={() => setMuted(!muted)}
      />
    </VideoBox>
  );
}

export const VideoBoxF = styled.div`
  width: 100%;
  height: 100vh;
`;

export function BannerFull({ setShow, url }: IBannerView) {
  const [muted, setMuted] = useState(true);

  const [ref, inView] = useInView({
    threshold: 1,
    onChange: (inView) => {
      setShow(!inView);
    },
  });

  useEffect(() => {
    var video = document.querySelector("video");
    if (video) video.muted = muted;
  }, [muted]);

  return (
    <VideoBoxF ref={ref}>
      <video
        src={url}
        width="100%"
        height="100%"
        object-fit="cover"
        muted
        playsInline
        autoPlay
        loop
        onClick={() => setMuted(!muted)}
      />
    </VideoBoxF>
  );
}
