import {
  SectionB,
  LogoBackgroundB,
  handleOpenNewTab,
  LogoPlaysongs,
  LogoCharlie,
  LogoPlaysongsHome,
} from "../../Componets/common";

export default function BFamilyStie() {
  return (
    <SectionB>
      <LogoBackgroundB
        onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
      >
        <LogoPlaysongs
          src="https://playsongshome.playsongs.ai/bicycle/logo-playsongs%403x.png"
          alt=""
          width={"100%"}
          className="playsongs_logo"
        />
      </LogoBackgroundB>
      <LogoBackgroundB
        onClick={() => handleOpenNewTab("https://www.charliesbigredhouse.com/")}
      >
        <LogoCharlie
          src="https://playsongshome.playsongs.ai/bicycle/logo-charlie%403x.png"
          alt=""
          width="100%"
          className="charlie_logo"
        />
      </LogoBackgroundB>
      <LogoBackgroundB
        onClick={() => handleOpenNewTab("http://www.playsongshome.com")}
      >
        <LogoPlaysongsHome
          src="https://playsongshome.playsongs.ai/bicycle/logo-playsongshome%403x.png"
          alt=""
          width="100%"
          className="playsongshome_logo"
        />
      </LogoBackgroundB>
    </SectionB>
  );
}

/*
<SectionB>
        <MLogoBackground
          onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
        >
          <img
            src="https://playsongshome.playsongs.ai/bicycle/logo-playsongs%403x.png"
            alt=""
            width={"100%"}
            className="mplaysongs_logo"
          />
        </MLogoBackground>
        <MLogoBackground
          onClick={() =>
            handleOpenNewTab("https://www.charliesbigredhouse.com/")
          }
        >
          <img
            src="https://playsongshome.playsongs.ai/bicycle/logo-charlie%403x.png"
            alt=""
            width="100%"
            className="mcharlie_logo"
          />
        </MLogoBackground>
        <MLogoBackground
          onClick={() => handleOpenNewTab("http://www.playsongshome.com")}
        >
          <img
            src="https://playsongshome.playsongs.ai/bicycle/logo-playsongshome%403x.png"
            alt=""
            width="100%"
            className="mplaysongshome_logo"
          />
        </MLogoBackground>
      </SectionB>
*/
