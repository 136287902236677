import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import NetworkManager from "../../Network/NetworkManager";
import { useModal } from "../../Componets/ModalContext";

interface ApplyFormProps {
  isOpen: boolean;
}

export interface DialogueData {
  centerId: number;
  childName: string;
  parentName: string;
  phone: string;
  birthday: string;
  inquiry?: string;
  token: string; // reCAPTCHA token
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400, // 모달 창의 너비 설정
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const backdropStyle = {
  color: "#fff", // 글자색 설정
  backgroundColor: "rgba(0, 0, 0, 0.5)", // 배경 알파값 설정
};

export enum Center {
  ap = 3,
  gw = 2,
}
export const CenterOptions = [
  { id: Center.ap, name: "압구정" },
  { id: Center.gw, name: "광교" },
];

const ApplyForm = () => {
  const navigate = useNavigate();
  const { isModalOpen, closeModal } = useModal();

  const [childName, setChildName] = useState("");
  const [parentName, setParentName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [centerId, setCenterId] = useState<number>(Center.ap); // 초기 센터 ID

  const handleCaptcha = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleClose = () => {
    closeModal();
    // navigate(-1); // 이전 페이지로 돌아가기
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!captchaValue) {
      alert("Please verify you are not a robot!");
      return;
    }
    if (centerId === -1) {
      alert("센터를 선택해주세요.");
      return;
    }
    //  console.log(captchaValue);
    const formData: DialogueData = {
      centerId,
      childName,
      parentName,
      phone,
      birthday,
      inquiry,
      token: captchaValue,
    };
    try {
      const result = await NetworkManager.sendDialogue(formData);
      console.log("Dialogue data sent successfully:", result);
      alert("접수 되었습니다. ");
      handleClose();
    } catch (error) {
      console.error("Failed to send dialogue data:", error);
      alert("다시 시도해주세요.");
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleClose()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={backdropStyle}
    >
      <Box sx={modalStyle}>
        <Typography id="apply-form-title" variant="h6" component="h2">
          신청서 작성
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            select
            label="센터 선택"
            value={centerId}
            onChange={(e) => setCenterId(Number(e.target.value))}
            fullWidth
          >
            {CenterOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            required
            fullWidth
            label="학생명"
            name="childName"
            autoComplete="name"
            onChange={(e) => setChildName(e.target.value)}
            value={childName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="학부모명"
            name="parentName"
            autoComplete="parent-name"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="연락처"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            inputProps={{ maxLength: 13 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="생년월일"
            name="birthday"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={birthday}
            onChange={(e) => setBirthday(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            label="문의사항"
            name="inquiry"
            value={inquiry}
            inputProps={{ maxLength: 255 }}
            onChange={(e) => setInquiry(e.target.value)}
          />

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPRCHA as string}
            onChange={handleCaptcha}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            제출하기
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
          >
            닫기
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ApplyForm;
