import { Language, LanguageDivision, scrollTo } from "./common";
import styled from "styled-components";
import { LanguageSelectView } from "./Navts";
import { useLanguage } from "./Languge/LanguageContext";

export const NavBack = styled.div<{ $sidebar: boolean }>`
  background: #707070;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ $sidebar }) => ($sidebar ? "0" : "-150%")};
  transition: 350ms;
  z-index: 10;
`;

export const SidebarNav = styled.div<{ $sidebar: boolean }>`
  background: #ffffff;
  left: ${({ $sidebar }) => ($sidebar ? "0" : "-150%")};
  display: flex;
  justify-content: end;
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 48.9583vw;
    //height: 106.901vw;
    height: 100vh;
  }
  @media (max-width: 767px) {
    width: 81.7949vw;
    //height: 137.6923vw;
    height: 100vh;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: end;
  position: fixed;

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 3.2031vw;
    height: 3.2031vw;
    margin-right: 4.359vw;
    margin-top: 2.3438vw;
  }
  @media (max-width: 767px) {
    width: 6.3077vw;
    height: 6.3077vw;
    margin-top: 5.0513vw;
    margin-right: 4.359vw;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Section = styled.div`
  clear: both;
  width: 81.7949vw;
`;
export const MoveLabel = styled.div`
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
    padding-left: 5.2083vw;
    height: 8.3333vw;
  }
  @media (max-width: 767px) {
    height: 16.0256vw;
    padding-left: 9.0698vw;
    font-size: 6.1538vw;
  }

  width: 100%;
  text-align: left;

  display: flex;
  align-items: center;
  color: #222;
  border-bottom: 0.5px solid #707070;

  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
`;

export interface ISidebarMenu {
  showSidebar: boolean;
  closeSidebar: () => void;
}

//

export default function SidebarMenu({
  showSidebar,
  closeSidebar,
}: ISidebarMenu) {
  const { language, setLanguage } = useLanguage();
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "ENG" ? "KOR" : "ENG"));
  };

  return (
    <NavBack $sidebar={showSidebar}>
      <SidebarNav $sidebar={showSidebar}>
        <CloseButton onClick={closeSidebar}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/button_x%403x.png"
            alt=""
            width={"100%"}
          />
        </CloseButton>

        <Section>
          <MoveLabel
            className="nav-label-gap"
            onClick={() => {
              scrollTo("Banner");
              closeSidebar();
            }}
          >
            home
          </MoveLabel>
          <MoveLabel
            onClick={() => {
              scrollTo("Philsophy");
              closeSidebar();
            }}
          >
            PhILOSOPHY
          </MoveLabel>
          <MoveLabel
            onClick={() => {
              scrollTo("History");
              closeSidebar();
            }}
          >
            hIstory
          </MoveLabel>
          <MoveLabel
            onClick={() => {
              scrollTo("Business");
              closeSidebar();
            }}
          >
            bUsINESS FIELD
          </MoveLabel>
          <MoveLabel
            onClick={() => {
              scrollTo("Contact");
              closeSidebar();
            }}
          >
            contact us
          </MoveLabel>
          <LanguageSelectView onClick={() => toggleLanguage()}>
            <Language select={language === "KOR"}>한국어</Language>{" "}
            <LanguageDivision />
            <Language select={language === "ENG"}>Eng</Language>
          </LanguageSelectView>
        </Section>
      </SidebarNav>
    </NavBack>
  );
}
