import { styled } from "styled-components";
import {
  IMenuProps,
  TitelSection,
  SectionView,
  TitelSectionB,
  MenuTextViewB,
  SectionViewB,
} from "../Navts";
import { Link } from "react-router-dom";
import { scrollTo } from "../common";
import { useModal } from "../ModalContext";
import { MouseEvent } from "react";

const LinkView = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1.17;
  text-align: center;
  color: #08348a;

  list-style: none;
  text-decoration: none;

  @media (max-width: 767px) {
    font-size: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.875vw;
  }
`;

const DropdownLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  color: #08348a;
  font-size: 0.8333vw;
  line-height: 1.19;

  text-align: center;

  margin-top: 1.0417vw;
`;
export interface IMenubar {
  item: IMenuProps;
  showMenubar: boolean;
  closeMenubar: () => void;
  openMenubar: () => void;
}

export default function PMenu({
  item,
  showMenubar,
  openMenubar,
  closeMenubar,
}: IMenubar) {
  const { openModal } = useModal();
  const handleMenuClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string
  ) => {
    if (path === "/apply") {
      e.preventDefault(); // 라우터 기본 동작 방지
      openModal(); // "/apply" 경로일 때 모달 열기
      closeMenubar(); // 메뉴바 닫기
    }
  };
  return (
    <SectionView>
      <TitelSection>
        <LinkView to={item.path} onMouseOver={openMenubar}>
          {item.title}
        </LinkView>
      </TitelSection>
      {showMenubar &&
        item.subNav !== undefined &&
        item.subNav.map((subitem, index) => {
          return (
            <DropdownLink
              key={index}
              to={subitem.path}
              onClick={(e) => handleMenuClick(e, subitem.path)}
            >
              {subitem.title}
            </DropdownLink>
          );
        })}
    </SectionView>
  );
}

export function BMenu({ item }: any) {
  return (
    <SectionViewB>
      <TitelSectionB onClick={() => scrollTo(item.path)}>
        <MenuTextViewB>{item.title}</MenuTextViewB>
      </TitelSectionB>
    </SectionViewB>
  );
}
