import BBusiness, { BBusinessM } from "./BBusiness";
import BContact, { BContactM } from "./BContact";
import BHistory, { BHistoryM } from "./BHistory";
import BPhilsophy, { BPhilsophyM, BPhilsophyTablet } from "./BPhilsophy";
import TopButton from "../../Componets/TopButton";
import { useState } from "react";
import { Desktop, Mobile, Tablet } from "../../Device";
import styled from "styled-components";

import Popup from "../../Componets/Popup";
import { useLanguage } from "../../Componets/Languge/LanguageContext";
import { Banner } from "../../Componets/Banner/Banner";
import BFamilyStie from "./BFamilySite";

const Div = styled.div<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  //background-color: aqua;
`;

export interface PopupData {
  id: string;
  URL: string;
  link: string;
  startday: string;
  endday: string;
}

export interface ILanguage {
  isKor: boolean;
}

export interface IDevice {
  isTablet: boolean;
}
export default function BHome() {
  const { language } = useLanguage();

  const [show, setShow] = useState(false);

  //// popup
  const [popupData, setPopupData] = useState<PopupData | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [load, setLoad] = useState(true);

  return (
    <>
      <>
        {openPopup && load && (
          <Popup
            closePopup={() => setOpenPopup(false)}
            clicklink={""}
            imglink={
              "https://playsongshome.playsongs.ai/popup/%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%89%E1%85%B5%E1%84%8F%E1%85%B3%E1%86%AF+17%E1%84%8C%E1%85%AE%E1%84%82%E1%85%A7%E1%86%AB+%E1%84%80%E1%85%B5%E1%84%82%E1%85%A7%E1%86%B7_%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%89%E1%85%B3%E1%84%90%E1%85%A1+%E1%84%86%E1%85%B5%E1%86%BE+%E1%84%8C%E1%85%A1%E1%84%89%E1%85%A1%E1%84%86%E1%85%A9%E1%86%AF+%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8.jpg"
            }
          />
        )}
      </>
      <Desktop>
        <Div id="Banner" height={""}>
          <Banner
            setShow={setShow}
            url="https://playsongshome.playsongs.ai/bicycle/logo-ani2.mp4"
          />
        </Div>
        <Div id="Philsophy" height={"56.2500vw"}>
          <BPhilsophy isKor={language === "KOR"} />
        </Div>
        <Div id="History" height={"57.2500vw"}>
          <BHistory isKor={language === "KOR"} />
        </Div>
        <Div id="Business" height={"50vw"}>
          <BBusiness isKor={language === "KOR"} />
        </Div>
        <Div id="Familysite" height={"20.6250vw"}>
          <BFamilyStie />
        </Div>
        <Div id="Contact" height={"29.375vw"}>
          <BContact isKor={language === "KOR"} isTablet={false} />
        </Div>
        <TopButton show={show} />
      </Desktop>

      <Tablet>
        <Div
          id="Banner"
          height={"46.8750vw"}
          style={{ paddingTop: "11.9792vw" }}
        >
          <Banner
            setShow={setShow}
            url="https://playsongshome.playsongs.ai/bicycle/logo-ani2.mp4"
          />
        </Div>
        <Div height={"46.0938vw"}>
          <BPhilsophyTablet isKor={language === "KOR"} />
        </Div>
        <Div id="History" height={"91.7969vw"}>
          <BHistory isKor={language === "KOR"} />
        </Div>
        <Div id="Business" height={"60.9375vw"}>
          <BBusiness isKor={language === "KOR"} />
        </Div>
        <Div id="Familysite" height={"13.0208vw"}>
          <BFamilyStie />
        </Div>
        <Div id="Contact" height={"48.9583vw"}>
          <BContact isKor={language === "KOR"} isTablet={true} />
        </Div>
        <TopButton show={show} />
      </Tablet>

      <Mobile>
        <Div
          id="Banner"
          height={"61.5385vw"}
          style={{ paddingTop: "15.3488vw" }}
        >
          <Banner
            setShow={setShow}
            url="https://playsongshome.playsongs.ai/bicycle/logo-ani2.mp4"
          />
        </Div>
        <Div id="Philsophy" height={"61.5385vw"}>
          <BPhilsophyM isKor={language === "KOR"} />
        </Div>
        <Div id="History" height={"176.1538vw"}>
          <BHistoryM isKor={language === "KOR"} />
        </Div>
        <Div id="Business" height={"83.5897vw"}>
          <BBusinessM isKor={language === "KOR"} />
        </Div>
        <Div id="Familysite" height={"25.6410vw"}>
          <BFamilyStie />
        </Div>
        <Div id="Contact" height={""}>
          <BContactM isKor={language === "KOR"} />
        </Div>
        <TopButton show={show} />
      </Mobile>
    </>
  );
}
