import styled, { css, keyframes } from "styled-components";
import scrollIntoView from "scroll-into-view-if-needed";

export const BBody = styled.div`
  @media (min-width: 1024px) {
    //margin: 6.25vw 1.7188vw 0;
    margin-left: 1.7188vw;
    margin-right: 1.7188vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 10.4167vw;
    margin-right: 10.4167vw;
  }
  @media (max-width: 767px) {
    margin-left: 2.3256vw;
    margin-right: 2.3256vw;
  }

  overflow-y: hidden;
  overflow-x: hidden;
  // background-color: blue;
`;

export const Language = styled.span<{ select: boolean }>`
  @media (min-width: 1024px) {
    width: 2.5vw;
    height: 1.0938vw;
    font-size: 0.9375vw;
    line-height: 1.17;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.0833vw;
    line-height: 1.19;
  }
  @media (max-width: 767px) {
    font-size: 4.1131vw;
    line-height: 1.19;
  }
  font-weight: bold;
  text-align: left;
  color: ${(props) => (props.select ? "#222" : "#bdbdbd")};
`;

export const LanguageDivision = styled.div`
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 0;
    height: 2.474vw;
    // margin-left: 4.6224vw;
    //  margin-right: 4.6224vw;
    border: solid 0.1302vw #222;
  }
  @media (max-width: 767px) {
    width: 0;
    height: 4.8843vw;
    margin-left: 1.5424vw;
    margin-right: 1.5424vw;
    border: solid 0.2571vw #222;
  }
`;
export const SectionView = styled.div`
  width: 100%;
  object-fit: cover;
`;

export const BodyView = styled.div`
  margin-left: 1.7188vw;
  margin-right: 1.7188vw;
`;

export const PathStringView = styled.div`
  margin-top: 1.0417vw;
  margin-bottom: 0.7813vw;
`;

export const BannerView = styled.div`
  margin-top: 4.1667vw;
  clear: both;
  width: 68.8542vw;
  // height: 50.2083vw;
`;

export const TitleView = styled.div`
  margin-top: 1.25vw;
  width: 68.8542vw;
  height: 2.5vw;
  font-family: Ysabeau SC;
  font-size: 1.875vw;
  font-weight: 800;
  line-height: 2.5vw;
  text-align: left;
  color: red;
  text-transform: uppercase;
`;
export const InfoView = styled.div`
  margin-top: 0.625vw;
  display: flex;
  justify-content: flex-start;
`;

export const InfoDescriptionView = styled.div`
  width: 40.9375vw;
`;

export const InfoTextView = styled.div`
  width: 40.9375vw;

  font-family: Ysabeau SC;
  font-size: 1.25vw;
  line-height: 2.0833vw;
  text-align: left;
  color: #222222;
  //text-transform: uppercase;
`;

export const InfoText2View = styled(InfoTextView)`
  margin-top: 1.25vw;
  width: 41.0417vw;
  font-family: Pretendard;
  line-height: 1.875vw;
`;

export const InfoImgView = styled.div`
  margin-top: 1.25vw;
  width: 40.9375vw;
  height: 26.4583vw;
  object-fit: contain;
`;
export const InfoTextView2 = styled(InfoTextView)`
  margin-top: 1.25vw;
`;
export const InfoLinkSectionView = styled.div`
  clear: both;
  position: relative;
  top: 0;
  width: 36.5104vw;
`;
export const InfoLinkView = styled.div`
  position: absolute;
  top: 1.6667vw;
  margin-left: 7.9167vw;
  width: 17.3958vw;
`;
export const InfoLinkTextView = styled.div`
  width: 17.3958vw;
  height: 2.2396vw;
  font-family: Ysabeau SC;
  font-size: 1.4583vw;
  font-weight: 800;
  line-height: 1.9792vw;
  text-align: left;
  color: red;
  &:hover {
    cursor: pointer;
  }
`;

export const InfoLinkText2View = styled(InfoLinkTextView)`
  margin-top: 1.0938vw;
`;
export const InfoImgSectionView = styled.div`
  margin-top: 0.9375vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoImgMiniView = styled.div`
  width: 20vw;
  height: 15.625vw;
  object-fit: contain;
`;

///////////////////////////////////////

export const MobileInfoLinkTextView = styled.div`
  margin-top: 9.3023vw;
  width: 77.6744vw;
  height: 8.3721vw;
  font-size: 5.5814vw;
  font-weight: 800;
  line-height: 7.4419vw;
  font-family: Ysabeau SC;
  text-align: left;
  color: red;
  &:hover {
    cursor: pointer;
  }
`;

export const MobileBodyView = styled.div`
  margin-left: 2.5641vw;
  margin-right: 2.5641vw;
`;

export const MobileImageView = styled.div`
  width: 100%;
  height: 55.814vw;
`;

export const MobileImageView2 = styled(MobileImageView)`
  height: 98.6047vw;
`;

export const MobileBannerView = styled.div`
  width: 100%;
  height: 90.2326vw;
`;

export const MobileTitleView = styled.div`
  margin-top: 9.3023vw;
  margin-left: 3.7209vw;
  width: 93%;
  height: 9.5349vw;
  font-family: Ysabeau SC;
  font-size: 6.9767vw;
  font-weight: 700;
  line-height: 9.5349vw;
  letter-spacing: -0.1395vw;
  text-align: left;
  color: red;
  border-bottom: solid 2px red;
`;
export const MobileTitleView2 = styled.div`
  clear: both;
  margin-top: 3.7209vw;
  width: 92.5581vw;
  // height: 9.5349vw;
  font-family: Ysabeau SC;
  font-size: 6.9767vw;
  font-weight: 800;
  line-height: 9.5349vw;
  letter-spacing: -0.1395vw;
  text-align: left;
  color: red;
  text-transform: uppercase;
  border-bottom: solid 2px red;
`;
export const MobileInfoTextView = styled.div`
  margin-top: 1.8605vw;
  width: 92.5581vw;
  font-family: Ysabeau SC;
  font-size: 4.186vw;
  line-height: 6.5116vw;
  text-align: left;
  color: #707070;
  //text-transform: uppercase;
`;
export const MobileDescriptionView = styled.div`
  margin-top: 2.7907vw;
  margin-left: 3.7209vw;
  width: 90%;
  font-family: Ysabeau SC;
  font-size: 4.186vw;
  font-weight: 500;
  line-height: 6.5116vw;
  text-align: left;
  color: #707070;
`;

export const MobileInfoText2View = styled(MobileInfoTextView)`
  //height: 25.1163vw;
  font-family: Pretendard;
  letter-spacing: -0.093vw;
  color: #222222;
  text-transform: none;
`;
export const MobileImageView3 = styled(MobileImageView2)`
  margin-top: 2.7907vw;
  height: 59.5349vw;
`;

export const MobileInfoImgSectionView = styled(InfoImgSectionView)`
  margin-top: 2.7907vw;
`;

export const MobileInfoImgMiniView = styled.div`
  width: 45.1163vw;
  height: 35.1163vw;
  object-fit: contain;
`;

export const ScrollSnapWrap = styled.div`
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
`;

export const ScrollSnapWrap2 = styled.div`
  z-index: 2;
  position: relative;
  height: 100%;
`;

export const SnapDiv = styled.div`
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

export const SnapDiv2 = styled.div`
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
`;
export const SnapFooter = styled.div`
  scroll-snap-align: end;

  //  scroll-margin-top: 80px;
`;
////

export const SectionB = styled.div`
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LogoBackgroundB = styled.div`
  @media (min-width: 1024px) {
    width: 31.5625vw;
    height: 20.625vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 25.5208vw;
    height: 13.0208vw;
  }
  @media (max-width: 767px) {
    width: 30.7692vw;
    height: 25.641vw;
  }
  background-color: #fafafa;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

export const LogoPlaysongs = styled.img`
  @media (min-width: 1024px) {
  }

  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
    width: 14.1026vw;
  }
`;
export const LogoCharlie = styled.img`
  @media (min-width: 1024px) {
  }

  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
    width: 11.4872vw;
    height: 10.7692vw;
  }
`;
export const LogoPlaysongsHome = styled.img`
  @media (min-width: 1024px) {
  }

  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
    width: 11.7949vw;
    height: 12.3846vw;
  }
`;
//////////

export const handleOpenNewTab = (url: string | URL | undefined) => {
  if (!Boolean(url)) return;

  console.log("new Window");
  window.open(url, "_blank", "noopener, noreferrer");
};

export const handleLink = (url: string | URL) => {
  window.location.replace(url);
};

export const scrollTo = (targetId: string) => {
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    scrollIntoView(targetElement, {
      scrollMode: "if-needed",
      behavior: "smooth",
    });
  } else {
    console.error(`Element with id '${targetId}' not found`);
  }
};

// animations
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const FadeIn = ($visible: boolean, time: number, delay: number) => css`
  visibility: ${$visible ? "visible" : "hidden"};
  z-index: 15;
  animation: ${$visible ? fadeIn : fadeOut} ${time}s ease-out ${delay}ms;
  transition: visibility ${delay}ms ease-out;
`;
