import { useRef, useState } from "react";
import { styled } from "styled-components";
import BannerImage from "../../Componets/Banner/BannerImage";
import { handleOpenNewTab } from "../../Componets/common";
import ScheduleCard, { ScheduleTimeCard } from "../../Componets/ScheduleCard";
import { Mobile, Tablet, Desktop, Default } from "../../Device";
import { apScheduleData, apScheduleTimeData } from "../../Json/ap_shcedule";
import { gwScheduleData, gwScheduleTimeData } from "../../Json/gw_schedule";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useDrag from "../../Componets/useDrag";
import usePreventBodyScroll from "../../usePreventBodyScroll";
import Popup from "../../Componets/Popup";
import PHeader from "./PHeader";
import Footer from "../../Componets/Footer";
import {
  ActionButton,
  Back,
  Body,
  ButtonView,
  ScheduleInfoView,
  SelectSectionView,
} from "./playsongsts";

const CenterTitleView = styled.div`
  margin-top: 3.125vw;
  margin-left: 16.7708vw;

  font-size: 2.6042vw;
  line-height: 1.3;
  font-weight: bold;
  color: #222;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
    margin-left: 6.9767vw;
    font-size: 5.5814vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 10.4167vw;
    margin-left: 12.7604vw;
    font-size: 3.125vw;
  }
`;

const CenterAdressView = styled.div`
  font-size: 1.875vw;
  //line-height: 1.81;
  letter-spacing: -0.0375vw;
  text-align: left;
  color: #222;
  margin-top: 1.25vw;
  margin-left: 16.7708vw;

  @media (max-width: 767px) {
    margin-top: 2.7907vw;
    margin-left: 6.9767vw;
    font-size: 5.1163vw;
    letter-spacing: -0.1023vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    margin-left: 12.7604vw;
    font-size: 2.8646vw;
    letter-spacing: -0.0573vw;
  }
`;

const CenterTelView = styled.div`
  font-weight: bold;
  line-height: 1.17;
  font-size: 1.875vw;
  color: #000;
  margin-top: 0.8333vw;
  margin-left: 16.7708vw;

  @media (max-width: 767px) {
    margin-top: 1.8605vw;
    margin-left: 6.9767vw;
    font-size: 5.1163vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
    margin-left: 12.7604vw;
    font-size: 2.8646vw;
  }
`;

export default function PSchedule() {
  return (
    <Back>
      <PHeader />
      <Body>
        <ScheduleView />
        <Footer flagheight={true} />
      </Body>
    </Back>
  );
}

function ScheduleView() {
  const [apSelect, setApSelect] = useState(true);
  function OpenSchedule(ap: boolean) {
    if (ap === true)
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/schedule/2024QTY4.jpg"
      );
    else
      handleOpenNewTab(
        "https://playsongshome.playsongs.ai/playsongs/schedule/gw-2024QTY3-1.jpg"
      );
  }

  //// popup
  //const [popupData, setPopupData] = useState<PopupData | null>();
  const [openPopup, setOpenPopup] = useState(false);
  const [load, setLoad] = useState(true);

  /* const { loading, data, error } = useAxios({
         url: "https://playsongshome.playsongs.ai/popup/popup-playsongs.json"
     });
 
     useEffect(() => {
 
         if (load === true) return;
         if (!data) return;
         var jsondata = JSON.stringify(data);
 
         let josnparse: PopupData = JSON.parse(jsondata);
         //    josnparse.startday = "2023-10-24 11:00:00";
         //  josnparse.endday = "2023-10-25 15:00:00";
         setPopupData(josnparse);
         setOpenPopup(true);
         setLoad(true);
     }, [data, load, loading]);
 */
  return (
    <>
      <>
        {openPopup && load && (
          <Popup
            closePopup={() => setOpenPopup(false)}
            clicklink={""}
            imglink={
              "https://playsongshome.playsongs.ai/popup/%E1%84%89%E1%85%AE%E1%84%80%E1%85%A1%E1%86%BC%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8600-600+(1).jpg"
            }
          />
        )}
      </>

      <Mobile>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/mo_banner%403x.png"
          }
        />
      </Mobile>
      <Tablet>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/ta_banner%403x.png"
          }
        />
      </Tablet>
      <Desktop>
        <BannerImage
          url={
            "https://playsongshome.playsongs.ai/playsongs/schedule/banner%403x.png"
          }
        />
      </Desktop>

      <SelectSectionView>
        <ButtonView onClick={() => setApSelect(true)} $select={apSelect}>
          <span>압구정 센터</span>
        </ButtonView>
        <ButtonView onClick={() => setApSelect(false)} $select={!apSelect}>
          <span>광교 센터</span>
        </ButtonView>
      </SelectSectionView>

      <TimeZone isAp={apSelect} />

      {apSelect ? (
        <>
          <CenterTitleView>압구정 센터</CenterTitleView>
          <CenterAdressView>
            서울 강남구 압구정로 113-22 2층 1호
          </CenterAdressView>
          <CenterTelView>1661-3210(2번) / 02-544-6636</CenterTelView>

          <Mobile>
            <SelectSectionView>
              <ActionButton onClick={() => OpenSchedule(true)} $select={true}>
                <span>시간표 확인하기</span>
              </ActionButton>
            </SelectSectionView>
            <SelectSectionView>
              <ActionButton
                onClick={() =>
                  handleOpenNewTab(
                    "https://playsongshome.playsongs.ai/playsongs/schedule/2024QTY4-month.jpg"
                  )
                }
                $select={true}
              >
                <span>월령표 확인하기</span>
              </ActionButton>
            </SelectSectionView>
            <ScheduleInfoView className="text_wrap">
              재원생 클래스 변경으로 인해, {"\n"}신규 등록 전 마감된 클래스가
              {"\n"}생길 수 있음을 알려드립니다.{"\n\n"}한 클래스에 3명 미만
              모집 시, {"\n"}오픈이 어려우며 다른 클래스로 {"\n"}이동 될 수
              있음을 알려드립니다.
              {"\n"}
            </ScheduleInfoView>
          </Mobile>

          <Default>
            <SelectSectionView>
              <ActionButton onClick={() => OpenSchedule(true)} $select={true}>
                <span>시간표 확인하기</span>
              </ActionButton>
              {
                <ActionButton
                  onClick={() =>
                    handleOpenNewTab(
                      "https://playsongshome.playsongs.ai/playsongs/schedule/2024QTY4-month.jpg"
                    )
                  }
                  $select={true}
                >
                  <span>월령표 확인하기</span>
                </ActionButton>
              }
            </SelectSectionView>
            <Tablet>
              <ScheduleInfoView className="text_wrap">
                재원생 클래스 변경으로 인해, 신규 등록 전 마감된 클래스가 생길
                수 있음을 알려드립니다.{"\n"}한 클래스에 3명 미만 모집 시,
                오픈이 어려우며 다른 클래스로 이동 될 수 있음을 알려드립니다.
                {"\n"}
              </ScheduleInfoView>
            </Tablet>
            <Desktop>
              <ScheduleInfoView className="text_wrap">
                재원생 클래스 변경으로 인해, 신규 등록 전 마감된 클래스가 생길
                수 있음을 알려드립니다.{"\n"}한 클래스에 3명 미만 모집 시,
                오픈이 어려우며 다른 클래스로 이동 될 수 있음을 알려드립니다.
                {"\n"}
              </ScheduleInfoView>
            </Desktop>
          </Default>
        </>
      ) : (
        <>
          <CenterTitleView>광교 센터</CenterTitleView>
          <CenterAdressView>
            경기 수원시 영통구 광교호수공원로 80 앨리웨이 키즈동 1층 (109호)
          </CenterAdressView>
          <CenterTelView>1661-3210(3번) / 031-304-6636</CenterTelView>

          <SelectSectionView>
            <ActionButton onClick={() => OpenSchedule(false)} $select={true}>
              <span>시간표 확인하기</span>
            </ActionButton>
          </SelectSectionView>
          <ScheduleInfoView className="text_wrap">
            참고용으로 확인부탁드립니다.{"\n"}
          </ScheduleInfoView>
        </>
      )}
    </>
  );
}

const ScheduleZoneSectionView = styled.div`
  clear: both;
  width: 66.6667vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.2083vw;
  display: flex;

  @media (max-width: 767px) {
    width: 85.1163vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
  }
`;
const TimeSection = styled.div`
  width: 4.5833vw;
  display: "inline-block";
  object-fit: contain;

  @media (max-width: 767px) {
    width: 18.6047vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10.4167vw;
  }
`;

const TimeZoneSectionView = styled.div`
  width: 62.0834vw;
  display: "inline-block";
  object-fit: contain;

  @media (max-width: 767px) {
    width: 66.5116vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 68.75vw;
  }
`;

export interface IDayProps {
  title: string;
  time: ITimeProps[];
}

export interface ITimeProps {
  data: ITimeSectionProps[];
}

export interface ITimeSectionProps {
  title: string;
  color: string;
  bold: boolean;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
function TimeZone({ isAp }: { isAp: boolean }) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const [selected, setSelected] = useState<string>("");
  const handleItemClick = (itemId: string) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };

  const apiRef = useRef({} as scrollVisibilityApiType);
  //console.log(apScheduleData, Gwang);

  return (
    <ScheduleZoneSectionView>
      <TimeSection>
        {isAp
          ? apScheduleTimeData.map((item: any, index: any) => {
              return <ScheduleTimeCard key={index} item={item} />;
            })
          : gwScheduleTimeData.map((item: any, index: any) => {
              return <ScheduleTimeCard key={index} item={item} />;
            })}
      </TimeSection>
      <TimeZoneSectionView
        onMouseEnter={disableScroll}
        onMouseLeave={enableScroll}
      >
        <ScrollMenu
          apiRef={apiRef}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          {isAp
            ? apScheduleData.map((item: any, index: any) => {
                return (
                  <ScheduleCard
                    key={index}
                    item={item}
                    onClick={handleItemClick(index)}
                    selected={index === selected}
                  />
                );
              })
            : gwScheduleData.map((item: any, index: any) => {
                return (
                  <ScheduleCard
                    key={index}
                    item={item}
                    onClick={handleItemClick(index)}
                    selected={index === selected}
                  />
                );
              })}
        </ScrollMenu>
      </TimeZoneSectionView>
    </ScheduleZoneSectionView>
  );
}
