import { styled } from "styled-components";
import { IDayProps } from "../screens/Playsongs/PSchedule";

const Section = styled.div`
  width: 12.5vw;
  display: "inline-block";
  object-fit: contain;

  @media (max-width: 767px) {
    width: 33.4884vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 26.0417vw;
  }
`;
const DaySectionView = styled.div`
  width: 100%;
  height: 2.4479vw;
  font-size: 1.0417vw;
  line-height: 2.4479vw;
  font-weight: bold;
  text-align: center;

  color: #222;
  border-top: solid 1px #707070;
  border-bottom: solid 1px #707070;
  @media (max-width: 767px) {
    font-size: 4.6512vw;
    height: 8.6047vw;
    line-height: 8.6047vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    height: 6.1198vw;
    line-height: 6.1198vw;
  }
`;
const TimeSectionView = styled.div`
  width: 100%;
  height: 6.6667vw;
  border-bottom: solid 1px #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 23.2558vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 16.6667vw;
  }
`;
const TimeLineView = styled.div<{ color: string; $bold: boolean }>`
  width: 100%;
  font-size: 0.9375vw;
  line-height: 1.67;
  letter-spacing: -0.0837vw;
  text-align: center;
  font-weight: ${(props) => (props.$bold ? "bold" : "normal")};
  color: ${(props) => props.color};

  @media (max-width: 767px) {
    font-size: 4.186vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
  }
`;

export default function ScheduleCard({
  item,
  selected,
  onClick,
}: {
  item: IDayProps;
  selected: boolean;
  onClick: Function;
}) {
  return (
    <Section onClick={() => onClick()}>
      <DaySectionView>{item.title}</DaySectionView>
      {item.time.map((data: any, _index: any) => {
        return (
          <TimeSectionView key={_index}>
            {data.map((timeline: any, index2: any) => {
              return (
                <TimeLineView
                  key={index2}
                  color={timeline.color}
                  $bold={timeline.bold}
                >
                  {timeline.title}
                </TimeLineView>
              );
            })}
          </TimeSectionView>
        );
      })}
    </Section>
  );
}

export function ScheduleTimeCard({ item }: { item: IDayProps }) {
  return (
    <>
      <DaySectionView />
      {item.time.map((data: any, _index: any) => {
        return (
          <TimeSectionView key={_index}>
            {data.map((timeline: any, index2: any) => {
              return (
                <TimeLineView
                  key={index2}
                  color={timeline.color}
                  $bold={timeline.bold}
                >
                  {timeline.title}
                </TimeLineView>
              );
            })}
          </TimeSectionView>
        );
      })}
    </>
  );
}
