
import "./../Css/button.css"
import { scrollTo } from './common';
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import { Default, Mobile } from "../Device";

interface ITop {
    show: boolean;
};

function TopButton({ show }: ITop) {

    return (
        <>
            <Default>
                <div className='topBtn_wrap'>
                    {show && (
                        <div className='topBtn' onClick={() => scrollTo('Banner')}>
                            <BsFillArrowUpCircleFill />
                        </div>
                    )}
                </div>
            </Default>
            <Mobile>
                <div className='mtopBtn_wrap'>
                    {show && (
                        <div className='topBtn' onClick={() => scrollTo('Banner')}>
                            <BsFillArrowUpCircleFill />
                        </div>
                    )}
                </div>
            </Mobile>
        </>
    );
}




export default TopButton;