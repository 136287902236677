import { DialogueData } from "../screens/Playsongs/ApplyForm";
import { httpClient } from "./HttpClient";

const NetworkManager = {
  // 로그인 함수
  login: async (email: string, password: string) => {
    try {
      const response = await httpClient.post("/auth/login", {
        nameKr: "테스트매니저1",
        email,
        password,
      });
      return response.data;
    } catch (error) {
      console.error("Error in login :", error);
      throw error;
    }
  },

  // 상담
  sendDialogue: async (data: DialogueData) => {
    try {
      console.log("URL : ", process.env.REACT_APP_SERVER);
      const response = await httpClient.post("/c-dialogue", data);
      return response.data;
    } catch (error) {
      console.error("Error in sending dialogue data:", error);
      throw error;
    }
  },

  // 데이터 가져오기 함수
  fetchData: async () => {
    try {
      const response = await httpClient.get("/data");
      return response.data;
    } catch (error) {
      console.error("Error in login : 데이터 가져오기 실패 \n", error);
      throw error;
    }
  },
};

export default NetworkManager;
