import { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { useModal } from "../ModalContext";
import { IMenuProps } from "../Navts";

export const SidebarLink = styled(Link)<{ $itemNav: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-decoration: none;

  width: 100%;
  height: 8.3333vw;
  padding-left: 4.8177vw;
  font-size: 2.6042vw;
  text-align: left;
  font-weight: bold;

  color: #08348a;
  border-bottom: 0.5px solid #707070;

  border-bottom: 0.5px solid ${($itemNav) => ($itemNav ? "#eeeeee" : "#707070")};
  background-color: ${($itemNav) => ($itemNav ? "#eeeeee" : "#ffffff")};

  //text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: 4.6512vw;
    height: 14.6512vw;
    padding-left: 4.8177vw;
  }
`;
const DropIconView = styled.div`
  margin-right: 4.9767vw;
`;

const DropdownLink = styled(Link)`
  background: #eeeeee;
  display: flex;
  align-items: center;
  text-decoration: none;

  height: 8.3333vw;
  color: #08348a;
  font-size: 2.3438vw;
  line-height: 1.17;
  padding-left: 4.8177vw;

  @media (max-width: 767px) {
    font-size: 4.6512vw;
    height: 14.6512vw;
    padding-left: 4.8177vw;
  }
`;

export interface ISidebarSubMenuProps {
  item: IMenuProps;
  closeSidebar: () => void;
}

export default function PSidebarSubMenu({
  item,
  closeSidebar,
}: ISidebarSubMenuProps) {
  const [subnav, setSubnav] = useState(false);
  const { openModal } = useModal(); // 모달 열기 함수
  const showSubnav = () => setSubnav(!subnav);
  const sendDataToParent = () => {
    closeSidebar(); // 콜백 함수 호출하여 데이터 전달
  };
  const handleItemClick = () => {
    if (item.path === "/apply") {
      openModal();
    } else if (!item.subNav) {
      closeSidebar();
    } else {
      showSubnav();
    }
  };
  return (
    <>
      <SidebarLink
        $itemNav={item.subNav !== undefined && subnav}
        to={item.path}
        onClick={item.subNav ? item.subNav && showSubnav : sendDataToParent}
      >
        <div>{item.title}</div>
        <DropIconView>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </DropIconView>
      </SidebarLink>
      {subnav &&
        item.subNav !== undefined &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink
              to={item.path}
              key={index}
              onClick={(e) => {
                if (item.path === "/apply") {
                  e.preventDefault(); // 라우터 기본 동작 방지
                  openModal();
                  closeSidebar();
                } else {
                  closeSidebar();
                }
              }}
            >
              {item.title}
            </DropdownLink>
          );
        })}
    </>
  );
}
