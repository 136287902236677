import { styled } from "styled-components";
import { handleOpenNewTab } from "./common";
import "bootstrap/dist/css/bootstrap.css";
import { useScreenType } from "../Devicets";
import Strings from "../screens/Playsongs/string";

const SeparatorView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.8333vw;
    border: solid 0.0521vw #222;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    margin: 2.6042vw 0.013vw 0 0.1953vw;
    border: solid 0.1302vw #222;
  }

  @media (max-width: 767px) {
    width: 92.6744vw;
    margin: 3.2558vw 0 0;
    border: solid 0.2326vw #222;
  }
  height: 0;
`;
const SeparatorMainView = styled.div<{ $flagheight: boolean }>`
  @media (min-width: 1024px) {
    border: solid 0.0521vw #707070;
    margin-top: ${(props) => (props.$flagheight ? "15.1vw" : "0")};
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100vw;
    margin: 2.6042vw 0.013vw 0 0.1953vw;
    border: solid 0.1302vw #707070;
    margin-top: 15.625vw;
  }

  @media (max-width: 767px) {
    width: 92.6744vw;
    margin: 3.2558vw 0 0;
    border: solid 0.2326vw #707070;
    margin-top: 27.907vw;
  }
  height: 0;
`;

const MainView = styled.div`
  @media (min-width: 1024px) {
    width: 100vw;
    padding: 2.0833vw 19.1667vw 1.7708vw 16.6667vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 3.125vw 10.3385vw 1.8229vw 10.2865vw;
  }
  @media (max-width: 767px) {
    //margin-top: 6.9302vw;
    padding: 5.5814vw 3.6047vw 5.3488vw 3.7209vw;
  }

  background-color: #fff;
`;

const InfoView = styled.div``;

const CopyrightLineView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 2.3438vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 767px) {
  }
`;
const LogoView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  @media (min-width: 1024px) {
    width: 33.5938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 40.8854vw;
  }
  @media (max-width: 767px) {
    width: 73.0233vw;
  }
`;

const OtherLogoView = styled(LogoView)`
  @media (min-width: 1024px) {
    width: 24.1016vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 27.2786vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.2558vw;
    width: 47.6744vw;
  }
`;

const OtherLogo = styled.div`
  @media (min-width: 1024px) {
    width: 4.7656vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 5.2083vw;
  }
  @media (max-width: 767px) {
    width: 9.3023vw;
  }
`;

const Other2Logo = styled.div`
  @media (min-width: 1024px) {
    width: 7vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 8.8542vw;
  }
  @media (max-width: 767px) {
    width: 15.814vw;
  }
`;
const LogoBicycleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.7292vw;
    width: 9.0078vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 12.867vw;
  }
  @media (max-width: 767px) {
    width: 21.1628vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogoLineView = styled.div`
  @media (min-width: 1024px) {
    width: 0.0781vw;
    height: 2.6016vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 0.1042vw;
    height: 3.4896vw;
  }
  @media (max-width: 767px) {
    height: 7.1279vw;
    width: 0.2vw;
  }
  background-color: #222;
`;
const LogoPlaysongsView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.849vw;
    width: 5.3438vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 7.525vw;
  }
  @media (max-width: 767px) {
    width: 12.3256vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogoHomeView = styled.div`
  @media (min-width: 1024px) {
    width: 5.3281vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.4323vw;
  }
  @media (max-width: 767px) {
    width: 11.4884vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LogocharliesView = styled.div`
  @media (min-width: 1024px) {
    width: 4.9531vw;
    margin-top: 0.2083vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.794vw;
  }
  @media (max-width: 767px) {
    width: 11.1628vw;
  }

  &:hover {
    cursor: pointer;
  }
`;
const CopyrightTextView = styled.div`
  line-height: 1.86;
  @media (min-width: 1024px) {
    // margin-top: 1.5625vw;
    font-size: 1.25vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.8229vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.4vw;
    font-size: 3.7209vw;
    line-height: 1.63;
  }
  text-align: left;
  color: #222;
`;
const TextView = styled.div`
  line-height: 1.86;
  @media (min-width: 1024px) {
    margin-top: 1.5625vw;
    font-size: 1.0938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.0313vw;
    font-size: 1.8229vw;
  }
  @media (max-width: 767px) {
    margin-top: 4.4vw;
    font-size: 3.7209vw;
    line-height: 1.63;
  }
  text-align: left;
  color: #222;
`;

const TitleTextView = styled.span`
  @media (min-width: 1024px) {
    font-size: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.0833vw;
  }
  @media (max-width: 767px) {
    font-size: 3.7209vw;
  }
  color: #222;
`;

export default function Footer({ flagheight }: { flagheight: boolean }) {
  return (
    <footer>
      <DesktopFooter flagheight={flagheight} />
    </footer>
  );
}

function DesktopFooter({ flagheight }: { flagheight: boolean }) {
  const screenType = useScreenType();
  return (
    <>
      {flagheight && <SeparatorMainView $flagheight={flagheight} />}
      <MainView>
        <InfoView>
          <LogoView>
            <LogoBicycleView
              onClick={() =>
                handleOpenNewTab("http://www.playsongs.co.kr/bicycle/")
              }
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/bicycle%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoBicycleView>
            <LogoLineView />
            <LogoPlaysongsView
              onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/ps%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoPlaysongsView>
            <LogoHomeView
              onClick={() => handleOpenNewTab("https://playsongshome.com/")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/home%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoHomeView>
            <LogocharliesView
              onClick={() =>
                handleOpenNewTab("https://www.charliesbigredhouse.com/")
              }
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/charlies%403x.png"
                alt=""
                width={"100%"}
              />
            </LogocharliesView>
          </LogoView>

          <TextView className="text_wrap">
            <TitleTextView>{Strings.footerString.title}</TitleTextView>
            {Strings.footerString.description[screenType]}
          </TextView>
        </InfoView>
        <SeparatorView />
        <CopyrightLineView>
          <CopyrightTextView>{Strings.footerString.copy}</CopyrightTextView>

          <OtherLogoView>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/ieyc-square-logo-with-text%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/mom-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/bcli%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <Other2Logo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/choice-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </Other2Logo>
          </OtherLogoView>
        </CopyrightLineView>
      </MainView>
    </>
  );
}
