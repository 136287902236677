import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { styled } from "styled-components";
import { FadeIn } from "../../Componets/common";
import { IDevice, ILanguage } from "./BHome";

const Background = styled.div`
  @media (min-width: 1024px) {
    background-image: url("https://playsongshome.playsongs.ai/bicycle/philosophy%403x.jpg");
  }
  @media (max-width: 1023px) {
    background-image: url("https://playsongshome.playsongs.ai/bicycle/tablet/philosophy%403x.png");
  }
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Title = styled.div<{ visible: boolean; time: number; delay: number }>`
  ${(props) => FadeIn(props.visible, props.time, props.delay)}

  width: 100%;
  @media (min-width: 1024px) {
    padding-top: 21.1719vw;
    font-size: 1.875vw;
    line-height: 1.21;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 16.0156vw;
    font-size: 3.6458vw;
    line-height: 1.21;
  }
  @media (max-width: 767px) {
  }
  font-family: Pretendard;
  font-weight: 900;
  text-align: center;
  color: #fff;
`;

const Description = styled.div<{
  visible: boolean;
  time: number;
  delay: number;
}>`
  ${(props) => FadeIn(props.visible, props.time, props.delay)}

  width: 100%;

  @media (min-width: 1024px) {
    height: 5.0781vw;
    margin: 1.5625vw 0 0;
    font-size: 3.9063vw;
    line-height: 1.3;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 1.5625vw 0 0;
    font-size: 3.125vw;
    line-height: 1.33;
  }
  @media (max-width: 767px) {
    font-size: 4.1026vw;
    line-height: 1.5;
  }
  font-weight: bold;
  text-align: center;
  color: #fff;
`;
export default function BPhilsophy({isKor}:ILanguage) {
  return (<>
    { isKor ? 
      <BPhilsophyKor/> :
      <BPhilsophyEng/>
    }
</>    
  )
}

export function BPhilsophyTablet({isKor}:ILanguage) {
  return (<>
    { isKor ? 
      <BPhilsophyKor/> :
      <BPhilsophyMEng isTablet={true}/>
    }
</>    
  )
}
function BPhilsophyKor() {
  const [step0, setStep0] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const delay = 1000;

  const [ref, inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        setStep0(true);
        const timer = setTimeout(() => {
          setStpe2Delay();
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setStep0(false);
        setStep1(false);
        setStep2(false);
      }
    },
  });

  function setStpe2Delay() {
    setStep1(true);

    const timer = setTimeout(() => {
      setStep2(true);
    }, delay);
    return () => clearTimeout(timer);
  }

  return (
    <Background ref={ref}>
      <Title visible={step0} time={1} delay={0}>
        PHILSOPHY
      </Title>
      <Description visible={step1} time={1} delay={0}>
        아이들이 몰입 할 수 있는 최고의 이중언어 놀이,
      </Description>
      <Description visible={step2} time={1} delay={0}>
        탐구 교육과 더불어 세상의 가치를 만드는 기업
      </Description>
    </Background>
  );
}

function BPhilsophyMKor(){
  const [step0, setStep0] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const delay = 1000;

  const [ref, _inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        setStep0(true);
        const timer = setTimeout(() => {
          setStpe2Delay();
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setStep0(false);
        setStep1(false);
        setStep2(false);
        setStep3(false);
      }
    },
  });

  function setStpe2Delay() {
    setStep1(true);

    const timer = setTimeout(() => {
      setStpe3Delay();
    }, delay);
    return () => clearTimeout(timer);
  }
  function setStpe3Delay() {
    setStep2(true);

    const timer = setTimeout(() => {
      setStep3(true);
    }, delay);
    return () => clearTimeout(timer);
  }

  return (
    <Background ref={ref}>
      <div className="philsophy_top">
        <Description visible={step0} time={1} delay={0}>
          PHILSOPHY
        </Description>
        <Description
          visible={step1}
          time={1}
          delay={0}
          style={{ marginTop: "3.0769vw" }}
        >
          아이들이 몰입 할 수 있는
        </Description>
        <Description visible={step2} time={1} delay={0}>
          최고의 이중언어 놀이, 탐구 교육과
        </Description>
        <Description visible={step3} time={1} delay={0}>
          더불어 세상의 가치를 만드는 기업
        </Description>
      </div>
    </Background>
  );

}


function BPhilsophyEng(){
  const [step0, setStep0] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const delay = 1000;

  const [ref, _inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        setStep0(true);
        const timer = setTimeout(() => {
          setStpe2Delay();
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setStep0(false);
        setStep1(false);
        setStep2(false);
        setStep3(false);
      }
    },
  });

  function setStpe2Delay() {
    setStep1(true);

    const timer = setTimeout(() => {
      setStpe3Delay();
    }, delay);
    return () => clearTimeout(timer);
  }
  function setStpe3Delay() {
    setStep2(true);

    const timer = setTimeout(() => {
      setStep3(true);
    }, delay);
    return () => clearTimeout(timer);
  }

  return (
    <Background ref={ref}>
      <div className="philsophy_top">
        <Description visible={step0} time={1} delay={0}>
          PHILSOPHY
        </Description>
        <Description
          visible={step1}
          time={1}
          delay={0}
          style={{ marginTop: "3.0769vw" }}
        >
        Fostering inquiry-based learning through 
        </Description>
        <Description visible={step2} time={1} delay={0}>
        immersive dual-language play to create value
        </Description>
        <Description visible={step3} time={1} delay={0}>
        collaboratively in the world.
        </Description>
      </div>
    </Background>
  );

}

function BPhilsophyMEng( {isTablet }: IDevice){
  const [step0, setStep0] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  const delay = 1000;

  const [ref, _inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        setStep0(true);
        const timer = setTimeout(() => {
          setStpe2Delay();
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setStep0(false);
        setStep1(false);
        setStep2(false);
        setStep3(false);
        setStep4(false);
      }
    },
  });

  function setStpe2Delay() {
    setStep1(true);

    const timer = setTimeout(() => {
      setStpe3Delay();
    }, delay);
    return () => clearTimeout(timer);
  }
  function setStpe3Delay() {
    setStep2(true);

    const timer = setTimeout(() => {
      setStpe4Delay();
    }, delay);
    return () => clearTimeout(timer);
  }

  function setStpe4Delay() {
    setStep3(true);

    const timer = setTimeout(() => {
      setStep4(true);
    }, delay);
    return () => clearTimeout(timer);
  }

  return (
    <Background ref={ref}>
      <div className={isTablet? "philsophy_e_top" : "philsophy_top"}>
        <Description visible={step0} time={1} delay={0}>
          PHILSOPHY
        </Description>
        <Description
          visible={step1}
          time={1}
          delay={0}
          style={{ marginTop: "3.0769vw" }}
        >
          Fostering inquiry-based learning 
        </Description>
        <Description visible={step2} time={1} delay={0}>
        through immersive dual-language
        </Description>
        <Description visible={step3} time={1} delay={0}>
        play to create value
        </Description>
        <Description visible={step4} time={1} delay={0}>
        collaboratively in the world.
        </Description>
      </div>
    </Background>
  );

}

export function BPhilsophyM( {isKor}:ILanguage ) {

  return (<>
    { isKor ? 
      <BPhilsophyMKor/> :
      <BPhilsophyMEng isTablet={false}/>
    }
  </>    
  )
  }
