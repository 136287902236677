import { styled } from "styled-components";
import { Default, Mobile } from "../Device";
import { handleOpenNewTab } from "./common";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const ModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 10%;
  width: 500px;
  height: 500px;
  z-index: 1001;
`;

const MobileModalContainer = styled(ModalContainer)`
  width: 300px;
  height: 300px;
`;

const ModalContent = styled.div`
  width: 100%;
  background-color: #fff;
`;
const ModalCloseContent = styled.div`
  clear: both;
  width: 100%;
  background-color: #fff;
`;

const ModalBaseCloseWrapper = styled.div`
  margin-left: 3.5%;
  margin-right: 3.5%;
  display: flex;
  justify-content: end;
  align-items: center;

  //color : #fff;
  color: #000;
  p {
    padding-top: 2%;
    cursor: pointer;
  }
`;

const ModalCloseWrapper = styled(ModalBaseCloseWrapper)`
  justify-content: space-between;
`;

interface IPopupProps {
  closePopup: () => void;
  clicklink: string;
  imglink: string;
}

function Popup({ closePopup, clicklink, imglink }: IPopupProps) {
  return (
    <Container>
      <ModalBackground />
      <Mobile>
        <MobileModalContainer>
          <ModalContent onClick={() => handleOpenNewTab(clicklink)}>
            <img src={imglink} alt="popuptest" width={"100%"} />
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </MobileModalContainer>
      </Mobile>

      <Default>
        <ModalContainer>
          <ModalContent onClick={() => handleOpenNewTab(clicklink)}>
            <img src={imglink} alt="popuptest" width={"100%"} />
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </ModalContainer>
      </Default>
    </Container>
  );
}

export default Popup;
