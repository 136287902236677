import { styled } from "styled-components";

export const Nav = styled.div<{ $menubaropen: boolean }>`
  width: 100vw;
  height: ${({ $menubaropen }) => ($menubaropen ? "12.4479vw" : "5.2083vw")}; //
  //height:5.2083vw;
  background-color: #fff; //#fff;
  position: fixed;
  z-index: 999;

  display: flex;
  object-fit: contain;

  @media (max-width: 767px) {
    height: 18.6047vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 13.0208vw;
  }
`;

export const NavB = styled(Nav)`
  height: ${({ $menubaropen }) => ($menubaropen ? "10.4167vw" : "6.2500vw")}; //
  position: fixed;
  @media (max-width: 767px) {
    height: 15.3488vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 11.9792vw;
  }
`;

export const SectionView = styled.div`
  width: 7.2917vw;
  display: inline-block;
  object-fit: contain;
  justify-content: center;
`;

export const SectionViewB = styled.div`
  display: inline-block;
  object-fit: contain;
  justify-content: center;
`;

export const TitelSection = styled.div`
  height: 5.2083vw;
  // background-color: aqua;
  display: flex;
  align-items: center;
`;

export const TitelSectionB = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    height: 6.25vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    height: 11.9792vw;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    width: 95.3488vw;
    height: 15.3488vw;
    justify-content: flex-start;
  }

  &:hover {
    cursor: pointer;
  }
  //background-color: aqua;
`;

export const LogoView = styled.img`
  width: 5.224vw;
  margin: auto 10.9219vw auto;
  display: "inline-block";
  object-fit: contain;

  @media (max-width: 767px) {
    width: 20.2326vw;
    margin: auto 7.2093vw auto;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 13.0599vw;
    margin: auto 10.4167vw auto;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const LogoViewB = styled.img`
  @media (min-width: 1024px) {
    width: 15.3125vw;
    margin: auto 8.125vw auto;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 25.5208vw;
    margin: auto 0 auto 21.875vw;
  }
  @media (max-width: 767px) {
    width: 34.8837vw;
    margin: auto 0 auto 21.875vw;
  }
  display: "inline-block";
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
`;

export const MenuTextViewB = styled.div`
  font-family: Pretendard;
  color: #222;
  font-weight: bold;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    font-size: 1.25vw;
    line-height: 1.19;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.875vw;
  }
  @media (max-width: 767px) {
    font-size: 1.875vw;
  }
`;

export const LanguageSelectView = styled.div`
  @media (min-width: 1024px) {
    margin-left: 3.75vw;
    margin-top: auto;
    margin-bottom: auto;
    width: 5.4688vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 5.4036vw;
    margin-top: 4.5573vw;
    width: 11.5885vw;
    height: 2.474vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    margin-top: 4.8843vw;
    margin-left: 9.7686vw;
    width: 22.8792vw;
    height: 4.8843vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    cursor: pointer;
  }
  // background-color: aliceblue;
`;

export const MenuSection = styled.div`
  width: 47.8646vw;
  display: flex;
  justify-content: space-between;
`;

export const MenuSectionB = styled.div`
  width: 43.8281vw;
  display: flex;
  justify-content: space-between;
`;
export const OutlinemenuView = styled.img`
  width: 4.8177vw;
  height: 2.8646vw;
  margin: auto 10.4167vw auto auto;

  @media (max-width: 767px) {
    width: 8.6047vw;
    height: 4.6512vw;
    margin: auto 7.2093vw auto auto;
  }
  //background-color:aqua;
`;

export const OutlinemenuViewB = styled.img`
  width: 4.6875vw;
  //height: 2.9557vw;
  //margin: auto 10.4167vw auto;

  @media (max-width: 767px) {
    width: 6.5116vw;
    // margin: auto 2.3256vw auto auto;
  }
  //background-color:aqua;
`;
export interface ISubNavProps {
  title: string;
  path: string;
  cName: string;
}
export interface IMenuProps {
  title: string;
  path: string;
  iconClosed?: any;
  iconOpened?: any;

  subNav?: ISubNavProps[];
}
